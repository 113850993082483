/**
 * 회원 관련 API
 */

import Api from "../configs/apiConfigs";
import {PageItemModel} from "../model/PageItemModel";
import {
  AdminSignUpReqDTO,
  CustomerBasicInfoDTO,
  CustomerInfoDTO, DeactivatedCustomerBasicInfoDTO, DeactivationStatisticsDTO,  // 새로 추가한 DTO
  MembershipInfoModel, SubscribedUserBasicInfoDTO,
  UserModel,
  UserPurchaseInfoDTO,
  UserRoleUpdateModel
} from "../model/UserModel";

export default class UsersApi extends Api {
  async getUsersLists(page: number, size: number, query: string) {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/auth/admin/get-user-list?page=${page + 1}&size=${size}&query=${query}`
      );

      const responseData = response.data as PageItemModel<UserModel>;
      return responseData;
    } catch (error) {
      console.error(error);
      return {
        currentPage: page,
        items: [],
        pageSize: size,
        totalCount: 0,
      };
    }
  }

  // 유저 정보 조회
  async getUsersInfo(userId: string) {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/auth/users/${userId}`
      );
      return response.data as UserModel;
    } catch (error) {
      throw error;
    }
  }

  async getUserMembershipInfo(userId: string) {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/auth/admin/membership/${userId}`
      );
      return response.data as MembershipInfoModel;
    } catch (error) {
      throw error;
    }
  }

  async updateUserRole(userId: string, formData: UserRoleUpdateModel) {
    try {
      const response = await super.put(
          `${process.env.REACT_APP_API_URL}/auth/admin/update-user-role/${userId}`,
          JSON.stringify(formData)
      );
      return response;
    } catch (error) {
      return [];
    }
  }

  async updateUserMembership(userId: string, formData: MembershipInfoModel) {
    try {
      const response = await super.put(
          `${process.env.REACT_APP_API_URL}/auth/admin/account/membership/${userId}`,
          JSON.stringify(formData)
      );
      return response;
    } catch (error) {
      return [];
    }
  }

  //모든 유저 조회
  async searchAdminUsers(
      page: number,
      size: number,
      searchType: string | null = null,
      query: string = ""
  ): Promise<{ items: CustomerBasicInfoDTO[], totalCount: number }> {
    try {
      let url = `${process.env.REACT_APP_API_URL}/auth/admin/search-admin-users?page=${page + 1}&size=${size}&query=${query}`;

      // searchType이 null이 아니면 URL에 searchType 추가
      if (searchType) {
        url += `&searchType=${searchType}`;
      }

      const response = await super.get(url);
      const data = response.data as { items: CustomerBasicInfoDTO[], totalCount: number }; // 타입 명시적으로 지정
      return data;
    } catch (error) {
      throw error;
    }
  }


  async searchSubscribedUsers(
      page: number,
      size: number,
      searchType: string | null = null,
      query: string = ""
  ): Promise<{ items: SubscribedUserBasicInfoDTO[], totalCount: number }> {
    try {
      let url = `${process.env.REACT_APP_API_URL}/auth/admin/search-subscribed-users?page=${page + 1}&size=${size}&query=${query}`;

      // searchType이 null이 아니면 URL에 searchType 추가
      if (searchType) {
        url += `&searchType=${searchType}`;
      }

      const response = await super.get(url);
      const data = response.data as { items: SubscribedUserBasicInfoDTO[], totalCount: number }; // 타입 명시적으로 지정
      return data;
    } catch (error) {
      throw error;
    }
  }


  // 특정 유저 정보 조회
  async getUserInfo(userId: number): Promise<CustomerInfoDTO> {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/auth/admin/get-user-info/${userId}`
      );
      return response.data as CustomerInfoDTO;
    } catch (error) {
      throw new Error('Failed to fetch user info');
    }
  }

  async getSubscribedUserBasicInfo(userId: number): Promise<SubscribedUserBasicInfoDTO> {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/auth/admin/get-subscribed-user-info/${userId}`
      );
      return response.data as SubscribedUserBasicInfoDTO;
    } catch (error) {
      throw new Error('Failed to fetch user info');
    }
  }

  // 유저 구매 정보 조회
  async getUserPurchaseInfo(userId: number): Promise<UserPurchaseInfoDTO> {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/payment/admin/user-purchase-info/${userId}`
      );
      return response.data as UserPurchaseInfoDTO;
    } catch (error) {
      throw new Error('Failed to fetch user purchase info');
    }
  }

  async getAllDeactivatedUsers(page: number, size: number, query: string): Promise<{
    items: DeactivatedCustomerBasicInfoDTO[],
    totalCount: number
  }> {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/auth/admin/deactivated-users?page=${page + 1}&size=${size}&query=${query}`
      );
      const data = response.data as {
        items: DeactivatedCustomerBasicInfoDTO[],
        totalCount: number
      }; // 타입 명시적으로 지정
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDeactivationStatistics(): Promise<DeactivationStatisticsDTO> {
    try {
      const response = await super.get(
          `${process.env.REACT_APP_API_URL}/auth/admin/deactivation-statistics`);
      return response.data as DeactivationStatisticsDTO;
    } catch (error) {
      throw new Error('Failed to fetch deactivation statistics');
    }
  }

  async createUser(data: AdminSignUpReqDTO): Promise<void> {
    try {
      await super.post(`${process.env.REACT_APP_API_URL}/auth/admin/signup`, data);
    } catch (error) {
      throw new Error('Failed to create user');
    }
  }

  // 전체 계정 수와 spacewalk를 제외한 계정 수 조회
  async getTotalAccountCount(): Promise<{
    totalAccounts: number,
    nonSpacewalkEmailAccounts: number
  }> {
    try {
      const response = await super.get(`${process.env.REACT_APP_API_URL}/auth/admin/total-account-count`);
      return response.data as { totalAccounts: number, nonSpacewalkEmailAccounts: number };
    } catch (error) {
      throw new Error('Failed to fetch account counts');
    }
  }

  // 멤버십 구독 중인 계정과 spacewalk를 제외한 계정 수 조회
  async getSubscribedAccountCount(): Promise<{
    totalSubscribedAccounts: number,
    nonSpacewalkEmailSubscribedAccounts: number
  }> {
    try {
      const response = await super.get(`${process.env.REACT_APP_API_URL}/auth/admin/subscribed-account-count`);
      return response.data as {
        totalSubscribedAccounts: number,
        nonSpacewalkEmailSubscribedAccounts: number
      };
    } catch (error) {
      throw new Error('Failed to fetch subscribed account counts');
    }
  }

  async getWeeklySignupStats(): Promise<{
    totalSignupsThisWeek: number,
    dailySignups: Record<string, number>
  }> {
    try {
      const response = await super.get(`${process.env.REACT_APP_API_URL}/auth/admin/weekly-signup`);
      return response.data as {
        totalSignupsThisWeek: number,
        dailySignups: Record<string, number>
      };
    } catch (error) {
      throw new Error('Failed to fetch weekly signup stats');
    }
  }

}
