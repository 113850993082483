import React, {useEffect, useState} from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid, TextField, Button,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import OrdersApi from '../../modules/order';
import UsersApi from '../../modules/users';
import {MonthlySalesDTO, MonthlyTicketSalesDTO, WeeklyTicketSalesDTO} from "../../model/OrderModel";

const DashboardPage: React.FC = () => {
  const [totalAccounts, setTotalAccounts] = useState<{
    totalAccounts: number,
    nonSpacewalkEmailAccounts: number
  } | null>(null);
  const [subscribedAccounts, setSubscribedAccounts] = useState<{
    totalSubscribedAccounts: number,
    nonSpacewalkEmailSubscribedAccounts: number
  } | null>(null);
  const [weeklySignups, setWeeklySignups] = useState<{
    totalSignupsThisWeek: number,
    dailySignups: Record<string, number>
  } | null>(null);
  const [weeklyTicketSales, setWeeklyTicketSales] = useState<WeeklyTicketSalesDTO[] | null>(null);
  const [monthlyTicketSales, setMonthlyTicketSales] = useState<MonthlyTicketSalesDTO | null>(null);
  const [monthlySales, setMonthlySales] = useState<MonthlySalesDTO[] | null>(null);
  const [monthCount, setMonthCount] = useState<number>(3);

  useEffect(() => {
    fetchTotalAccountCount();
    fetchSubscribedAccountCount();
    fetchWeeklySignupStats();
    fetchWeeklyTicketSales();
    fetchMonthlyTicketSales();
    fetchMonthlySales(monthCount);
  }, [monthCount]);

  // 요일 라벨링
  const daysOfWeekLabels: Record<string, string> = {
    MONDAY: '월',
    TUESDAY: '화',
    WEDNESDAY: '수',
    THURSDAY: '목',
    FRIDAY: '금',
    SATURDAY: '토',
    SUNDAY: '일',
  };

  // 공통 로직으로 데이터의 요일을 한글화 하는 함수
  const formatWeeklyData = (data: Record<string, number>) => {
    const daysOfWeekEnglish = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

    return daysOfWeekEnglish.map((day) => ({
      day: daysOfWeekLabels[day], // 라벨링 객체 사용
      count: data[day] ?? 0, // 데이터가 없는 경우 0으로 설정
    }));
  };

  const fetchTotalAccountCount = async () => {
    try {
      const usersApi = new UsersApi();
      const response = await usersApi.getTotalAccountCount();
      setTotalAccounts(response);
    } catch (error) {
      console.error('Failed to fetch total account counts:', error);
    }
  };

  const fetchSubscribedAccountCount = async () => {
    try {
      const usersApi = new UsersApi();
      const response = await usersApi.getSubscribedAccountCount();
      setSubscribedAccounts(response);
    } catch (error) {
      console.error('Failed to fetch subscribed account counts:', error);
    }
  };

  const fetchWeeklySignupStats = async () => {
    try {
      const usersApi = new UsersApi();
      const response = await usersApi.getWeeklySignupStats();
      setWeeklySignups(response);
    } catch (error) {
      console.error('Failed to fetch weekly signup stats:', error);
    }
  };

  const fetchWeeklyTicketSales = async () => {
    try {
      const ordersApi = new OrdersApi();
      const response = await ordersApi.getWeeklyTicketSales();
      setWeeklyTicketSales(response);
    } catch (error) {
      console.error('Failed to fetch weekly ticket sales:', error);
    }
  };

  const fetchMonthlyTicketSales = async () => {
    try {
      const ordersApi = new OrdersApi();
      const response = await ordersApi.getMonthlyTicketSales();
      setMonthlyTicketSales(response);
    } catch (error) {
      console.error('Failed to fetch monthly ticket sales:', error);
    }
  };

  const fetchMonthlySales = async (monthCount: number) => {
    try {
      const ordersApi = new OrdersApi();
      const response = await ordersApi.getMonthlySalesData(monthCount);
      setMonthlySales(response);
    } catch (error) {
      console.error('Failed to fetch monthly sales data:', error);
    }
  };

  const handleMonthCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthCount(parseInt(event.target.value, 10));
  };

  // 숫자를 로케일에 따라 쉼표로 포맷팅하는 함수
  const formatNumberWithCommas = (value: number) => {
    return value.toLocaleString(); // 수정: toLocaleString 사용하여 쉼표 추가
  };

  const renderStatisticBox = (title: string, value: number, subValue?: number) => (
      <Paper
          elevation={1}
          sx={{
            p: 2,
            height: '150px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'grey.200', // 약간 더 어두운 색상으로 변경하여 구분되도록 조정
            borderRadius: 2,
          }}
      >
        <Typography variant="h6" align="center" sx={{fontWeight: 'bold'}}>{title}</Typography>
        <Typography variant="h4" align="center"
                    sx={{color: 'primary.main'}}>{formatNumberWithCommas(value)}</Typography>
        {subValue !== undefined && (
            <Typography variant="subtitle1" color="textSecondary" align="center">
              @spacewalk 포함 ({formatNumberWithCommas(subValue)})
            </Typography>
        )}
      </Paper>
  );

  const renderWeeklySignupsBox = () => {
    if (!weeklySignups) return null;

    const data = formatWeeklyData(weeklySignups.dailySignups);

    return (
        <Paper elevation={1} sx={{
          p: 2,
          mb: 2,
          height: '250px',
          width: '100%',
          backgroundColor: 'grey.200',
          borderRadius: 2
        }}>
          <Box display="flex" height="100%">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="40%"
                borderRight="1px solid #ddd"
                pr={2}
            >
              <Typography variant="h6" align="center" sx={{fontWeight: 'bold'}}>이번 주 가입자 수</Typography>
              <Typography variant="h4" align="center" sx={{mt: 2, color: 'primary.main'}}>
                {formatNumberWithCommas(weeklySignups.totalSignupsThisWeek)}명
              </Typography>
            </Box>

            <Box width="60%" pl={2} display="flex" alignItems="center" justifyContent="center">
              <ResponsiveContainer width="100%" height={150}>
                <BarChart data={data}>
                  <XAxis dataKey="day"/>
                  <YAxis allowDecimals={false}/>
                  <Tooltip/>
                  <Bar dataKey="count" fill="#8884d8"/>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Paper>
    );
  };

  const renderWeeklyTicketSalesBox = () => {
    if (!weeklyTicketSales) return null;

    // 요일을 한글로 변환하여 그래프에 표시
    const data = weeklyTicketSales.map((sale) => ({
      day: daysOfWeekLabels[sale.dayOfWeek] || sale.dayOfWeek, // 한글 요일로 변환
      count: sale.totalTicketCount,
      amount: sale.totalOrderedPrice,
    }));

    // 총 판매 개수와 총 매출액 계산
    const totalTicketCount = weeklyTicketSales.reduce((sum, sale) => sum + sale.totalTicketCount, 0);
    const totalOrderedPrice = weeklyTicketSales.reduce((sum, sale) => sum + sale.totalOrderedPrice, 0);

    return (
        <Paper elevation={1} sx={{ p: 2, mb: 2, height: '250px', backgroundColor: 'grey.200', borderRadius: 2 }}>
          <Typography variant="h6" align="center" gutterBottom>
            이번 주 요일별 이용권 판매 현황<br/>
            총 {totalTicketCount.toLocaleString()} 개, {totalOrderedPrice.toLocaleString()}원
          </Typography>
          <ResponsiveContainer width="100%" height={150}>
            <BarChart data={data}>
              <XAxis dataKey="day"/>
              <YAxis allowDecimals={false}/>
              <Tooltip formatter={(value: number, name: string, props: any) => {
                if (props && props.payload) {
                  const {count, amount} = props.payload;
                  return [
                    `${count.toLocaleString()} 개 (${amount.toLocaleString()}원)`,
                    "판매수 및 판매액",
                  ];
                }
                return ["데이터 없음", "판매수 및 판매액"];
              }} />
              <Bar dataKey="count" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
    );
  };

  const renderMonthlyTicketSalesBox = () => {
    if (!monthlyTicketSales || !monthlySales) return null;

    const currentMonthSales = monthlySales.find(sale => sale.month === monthlyTicketSales.period);

    return (
        <Paper elevation={1} sx={{
          p: 2,
          mb: 2,
          height: '250px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'grey.200',
          borderRadius: 2
        }}>
          <Typography variant="h6" align="center">이번 달 이용권 판매</Typography>
          <Typography variant="h4" align="center"
                      sx={{color: 'primary.main'}}>{formatNumberWithCommas(monthlyTicketSales.totalTicketCount)}개</Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary">
            이용권 매출액: {formatNumberWithCommas(monthlyTicketSales.totalOrderedPrice)} 원
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary">
            구독 매출액: {formatNumberWithCommas(currentMonthSales?.membershipSales || 0)} 원
          </Typography>
        </Paper>
    );
  };

  const renderMonthlySalesBox = () => {
    if (!monthlySales) return null;

    // 최신 월을 제외하고 나머지 데이터만 필터링 (프론트에서 최신 달 제거)
    const filteredSales = monthlySales.slice(1).reverse().slice(0, monthCount);

    const data = filteredSales.map((sale) => ({
      month: sale.month,
      totalSales: sale.totalSales,
      membershipSales: sale.membershipSales,
      ticketSales: sale.ticketSales,
    }));

    return (
        <Paper elevation={1} sx={{
          p: 2,
          mb: 2,
          height: '350px',
          width: '100%',
          backgroundColor: 'grey.200',
          borderRadius: 2
        }}>
          <Box display="flex" justifyContent="space-between" alignItems="center"
               marginBottom="16px">
            <Typography variant="h6" align="center" gutterBottom>
              최근 월별 매출 현황
            </Typography>
            {/* 기간 입력 하는 부분 */}
            <TextField
                type="number"
                label="기간(개월)"
                variant="outlined"
                size="small"
                value={monthCount}
                onChange={handleMonthCountChange}
                sx={{width: '100px'}}
            />
          </Box>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={data}>
              <XAxis dataKey="month"/>
              <YAxis allowDecimals={false}/>
              <Tooltip formatter={(value: number, name: string) => {
                let label = name;
                if (name === 'totalSales') label = '총매출액';
                if (name === 'membershipSales') label = '구독매출';
                if (name === 'ticketSales') label = '이용권매출';
                return [
                  `${formatNumberWithCommas(value)} 원`,
                  label,
                ];
              }}/>
              <Bar dataKey="totalSales" fill="#8884d8" name="총매출액"/>
              <Bar dataKey="membershipSales" fill="#82ca9d" name="구독매출"/>
              <Bar dataKey="ticketSales" fill="#ffc658" name="이용권매출"/>
            </BarChart>
          </ResponsiveContainer>
        </Paper>
    );
  };

  return (
      <Box display="flex" flexWrap="wrap" justifyContent="space-around" sx={{width: '95%'}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            {totalAccounts &&
                renderStatisticBox('총 회원 수', totalAccounts.nonSpacewalkEmailAccounts, totalAccounts.totalAccounts)}
          </Grid>
          <Grid item xs={12} md={3}>
            {subscribedAccounts &&
                renderStatisticBox('구독 회원 수', subscribedAccounts.nonSpacewalkEmailSubscribedAccounts, subscribedAccounts.totalSubscribedAccounts)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderWeeklySignupsBox()}
          </Grid>
          <Grid item xs={12} md={2}>
            {renderMonthlyTicketSalesBox()}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderWeeklyTicketSalesBox()}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderMonthlySalesBox()}
          </Grid>
        </Grid>
      </Box>
  );
};

export default DashboardPage;
