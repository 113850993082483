export class ConvertLocalDateTimeToString {
  static convert(
    dateArray: [number, number, number, number, number, number, number]
  ): string {
    const [year, month, day, hour, minute, second, nanosecond] = dateArray
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
      2,
      '0'
    )}T${String(hour).padStart(2, '0')}:${String(minute).padStart(
      2,
      '0'
    )}:${String(second).padStart(2, '0')}.${String(nanosecond).padStart(
      9,
      '0'
    )}`
  }

  static convertString(dateString: string): string {
    if (dateString.length !== 20) {
      throw new Error('Invalid date string format')
    }
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)
    const day = dateString.slice(6, 8)
    const hour = dateString.slice(8, 10)
    const minute = dateString.slice(10, 12)
    const second = dateString.slice(12, 14)
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }

  static convertArray(
    dateArray: [number, number, number, number, number, number, number]
  ): string {
    const [year, month, day, hour, minute, second] = dateArray
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
      2,
      '0'
    )} ${String(hour).padStart(2, '0')}:${String(minute).padStart(
      2,
      '0'
    )}:${String(second).padStart(2, '0')}`
  }
}
