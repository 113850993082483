import Api from "../configs/apiConfigs";
import {PageItemModel} from "../model/PageItemModel";
import {
  ItemDTO, MonthlySalesDTO,
  MonthlyTicketSalesDTO,
  OrderSummaryDTO,
  RequestOrderReq,
  WeeklyTicketSalesDTO
} from "../model/OrderModel";


export default class OrdersApi extends Api {
  // 전체 주문 조회
  async getOrders(
      userId: number | null,
      page: number,
      size: number,
      orderId?: string, // 단일 orderId로 변경
      status?: string[],
      type?: string,
      startDate?: string,
      endDate?: string
  ): Promise<PageItemModel<OrderSummaryDTO>> {
    const params: any = {
      page: page,
      size: size,
      userId: userId !== null ? userId : undefined,
      orderId: orderId, // 단일 값으로 설정
      status: status ? status.join(',') : undefined,
      type: type || undefined,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
    }

    try {
      const response = await super.get<PageItemModel<OrderSummaryDTO>>(
          `${process.env.REACT_APP_API_URL}/payment/admin/orders`,
          {params}
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  // 특정 주문 조회
  async getOrderById(orderId: string): Promise<OrderSummaryDTO> {
    try {
      const response = await super.get<OrderSummaryDTO>(
          `${process.env.REACT_APP_API_URL}/payment/admin/orders/${orderId}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  // 주문 생성
  async createOrder(
      userId: number,
      data: RequestOrderReq
  ): Promise<OrderSummaryDTO> {
    try {
      const response = await super.post<OrderSummaryDTO>(
          `${process.env.REACT_APP_API_URL}/payment/${userId}/orders`,
          data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  // 특정 주문 조회
  async getOrderByIdAndStatus(
      orderId: string,
      status: string
  ): Promise<OrderSummaryDTO> {
    try {
      const response = await super.get<OrderSummaryDTO>(
          `${process.env.REACT_APP_API_URL}/payment/admin/orders/${orderId}`,
          {
            params: {status},
          }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async deleteOrder(orderId: string): Promise<void> {
    try {
      await super.delete(
          `${process.env.REACT_APP_API_URL}/payment/admin/orders/${orderId}`
      )
    } catch (error) {
      throw error
    }
  }

  async getItemsByType(types: string[]): Promise<ItemDTO[]> {
    try {
      const response = await super.get<ItemDTO[]>(
          `${process.env.REACT_APP_API_URL}/payment/admin/items`,
          {
            params: {
              types: types.join(','), // 타입들을 콤마로 구분하여 전달
            },
          }
      )
      return response.data
    } catch (error) {
      throw new Error('Failed to fetch items by types')
    }
  }

  // 이번주 요일 별 이용권 판매
  async getWeeklyTicketSales(): Promise<WeeklyTicketSalesDTO[]> {
    try {
      const response = await super.get<WeeklyTicketSalesDTO[]>(
          `${process.env.REACT_APP_API_URL}/payment/admin/weekly-ticket-sales`
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch weekly ticket sales');
    }
  }

  // 이번달 이용권 판매 현황 조회
  async getMonthlyTicketSales(): Promise<MonthlyTicketSalesDTO> {
    try {
      const response = await super.get<MonthlyTicketSalesDTO>(
          `${process.env.REACT_APP_API_URL}/payment/admin/monthly-ticket-sales`
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch monthly ticket sales');
    }
  }

  // 월간 매출 데이터 조회
  async getMonthlySalesData(monthCount: number): Promise<MonthlySalesDTO[]> {
    try {
      const response = await super.get<MonthlySalesDTO[]>(
          `${process.env.REACT_APP_API_URL}/payment/admin/monthly-sales`,
          {
            params: {
              monthCount: monthCount
            }
          }
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch monthly sales data');
    }
  }
}


