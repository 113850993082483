import Api from '../configs/apiConfigs'
import { PageModel } from '../model/common/PageModel'
import { PremiumLogRequestParamModel } from '../model/PremiumRequestLog/PremiumLogRequestParamModel'
import { PremiumLogResponseModel } from '../model/PremiumRequestLog/PremiumLogResponseModel'

export default class PremiumRequestLogApi extends Api {
  async getPremiumRequestLogs(
    params: PremiumLogRequestParamModel
  ): Promise<PageModel<PremiumLogResponseModel>> {
    try {
      const response = await super.get<PageModel<PremiumLogResponseModel>>(
        `${process.env.REACT_APP_API_URL}/admin/premium/log`,
        { params }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
}
