import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl,
  InputLabel,
  FormGroup,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import TicketApi from '../../modules/ticket';
import { TicketDTO } from '../../model/TicketModel';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// 플러그인 설정
dayjs.extend(utc);
dayjs.extend(timezone);

// 서울 시간대 설정
dayjs.tz.setDefault('Asia/Seoul');

interface UpdateTicketModalProps {
  ticket: TicketDTO; // 선택한 티켓 정보
  onClose: () => void; // 모달 닫기 핸들러
  onUpdateSuccess: () => void; // 업데이트 성공 시 호출할 핸들러
}

const UpdateTicketModal: React.FC<UpdateTicketModalProps> = ({ ticket, onClose, onUpdateSuccess }) => {
  const ticketApi = new TicketApi();

  // 기본값 설정
  const [status, setStatus] = useState<string>(ticket.status || 'AVAILABLE');
  const [usedAt, setUsedAt] = useState<Dayjs | null>(ticket.usedAt ? dayjs.utc(ticket.usedAt).tz('Asia/Seoul') : null);

  useEffect(() => {
    // 기존 티켓의 정보를 다시 설정
    setStatus(ticket.status || 'AVAILABLE');
    setUsedAt(ticket.usedAt ? dayjs.utc(ticket.usedAt).tz('Asia/Seoul') : null);
  }, [ticket]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value as string);
  };

  const handleUsedAtChange = (date: Dayjs | null) => {
    setUsedAt(date);
  };

  const handleSubmit = async () => {
    try {
      const usedAtFormatted = usedAt && usedAt.isValid() ? usedAt.toISOString() : null;
      await ticketApi.updateTicket([ticket.ticketId], status, usedAtFormatted);

      alert('이용권이 성공적으로 수정되었습니다.');
      onUpdateSuccess();
      onClose();
    } catch (error) {
      alert('이용권 수정 중 문제가 발생했습니다.');
    }
  };

  return (
      <Container maxWidth="sm">
        <Box mb={3}>
          <Typography variant="h5" gutterBottom>
            이용권 수정
          </Typography>

          <FormGroup sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>이용권 상태</InputLabel>
              <Select
                  label="이용권 상태"
                  value={status}
                  onChange={handleStatusChange}
                  required
              >
                <MenuItem value="AVAILABLE">사용가능</MenuItem>
                <MenuItem value="USED">사용완료</MenuItem>
                <MenuItem value="UPDATED">갱신됨</MenuItem>
                <MenuItem value="REFUNDED">환불</MenuItem>
                <MenuItem value="CANCELED">취소</MenuItem>
                <MenuItem value="EXPIRED">만료</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                  label="사용 일자"
                  value={usedAt}
                  onChange={handleUsedAtChange}
                  format="YYYY/MM/DD HH:mm:ss"
                  slotProps={{
                    textField: { fullWidth: true },
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
              />
            </LocalizationProvider>
          </FormGroup>

          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              수정
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose} style={{ marginLeft: '10px' }}>
              취소
            </Button>
          </Box>
        </Box>
      </Container>
  );
};

export default UpdateTicketModal;
