import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PushNotificationApi from "../../modules/push_notification";
import { PushNotificationModel } from "../../model/PushNotificationModel";
import { useNavigate } from "react-router-dom";

const PushNotificationListPage = () => {
  const [pushNotifications, setPushNotifications] = useState<
    PushNotificationModel[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState("");
  const [tempQuery, setTempQuery] = useState("");
  const navigate = useNavigate();

  const fetchPushNotifications = async (
    page: number,
    size: number,
    query: string
  ) => {
    setLoading(true);
    const api = new PushNotificationApi();
    const pushNotificationList = await api.getPushNotificationList(
      page,
      size,
      query
    );
    setPushNotifications(pushNotificationList.items);
    setTotalCount(pushNotificationList.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchPushNotifications(page, rowsPerPage, query);
  }, [page, rowsPerPage, query]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setPage(0);
    fetchPushNotifications(0, rowsPerPage, tempQuery);
  };

  const handleCreate = () => {
    navigate("/push_notification/form");
  };

  const handleUpdatePage = (id: number) => {
    navigate(`/push_notification/form/${id}`);
  };

  return (
    <>
      <Typography variant="h5" component="h5" gutterBottom>
        푸쉬 알림 리스트
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="[내용]을 입력해주세요."
          variant="outlined"
          value={tempQuery}
          onChange={(e) => setTempQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{ marginRight: "1rem" }}
          size="small"
        />
        <Box mr={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            className="mr-2"
          >
            검색
          </Button>
        </Box>
        <Box>
          <Button variant="contained" color="success" onClick={handleCreate}>
            등록
          </Button>
        </Box>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "4%" }}>
                    번호
                  </TableCell>
                  <TableCell>제목</TableCell>
                  <TableCell>내용</TableCell>
                  <TableCell>링크</TableCell>
                  <TableCell width={100}>예약일</TableCell>
                  <TableCell width={100}>발송일</TableCell>
                  <TableCell width={100}>등록일</TableCell>
                  <TableCell width={100}>수정일</TableCell>
                  <TableCell>관리</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pushNotifications.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">
                      {totalCount - (page * rowsPerPage + index)}
                    </TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.contents}</TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <Tooltip title={item.link} arrow>
                        <span
                          style={{
                            display: "inline-block",
                            maxWidth: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.link}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {item.reservationAt ? item.reservationAt : "-"}
                    </TableCell>
                    <TableCell>{item.pushedAt ? item.pushedAt : "-"}</TableCell>
                    <TableCell>{item.createdAt}</TableCell>
                    <TableCell>{item.updatedAt}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleUpdatePage(item.id)}
                      >
                        수정
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
};

export default PushNotificationListPage;
