import React from 'react'
import { Typography, Box } from '@mui/material'
import { ModalDTO } from '../../model/ModalModel'
import styles from '../../style/modal/ModalDetailPage.module.css'

interface ModalDetailViewProps {
  modal: ModalDTO
}

const ModalDetailView: React.FC<ModalDetailViewProps> = ({ modal }) => {
  return (
    <>
      <Typography className={styles.label}>ID:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.id}</Typography>
      </Box>
      <Typography className={styles.label}>제목:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value} style={{ fontWeight: 'bold' }}>
          {modal.title}
        </Typography>
      </Box>
      <Typography className={styles.label}>내용:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.content}</Typography>
      </Box>
      <Typography className={styles.label}>타입:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.modalType}</Typography>
      </Box>
      <Typography className={styles.label}>표시 여부:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>
          {modal.display ? '예' : '아니오'}
        </Typography>
      </Box>
      <Typography className={styles.label}>순서:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.order}</Typography>
      </Box>
      <Typography className={styles.label}>생성일:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.createdAt}</Typography>
      </Box>
      <Typography className={styles.label}>수정일:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.updatedAt}</Typography>
      </Box>
    </>
  )
}

export default ModalDetailView
