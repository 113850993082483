import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import OrdersApi from '../../modules/order';
import {OrderSummaryDTO} from '../../model/OrderModel';
import {CustomerBasicInfoDTO} from '../../model/UserModel';
import UsersApi from "../../modules/users";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import IconButton from "@mui/material/IconButton";
import {ContentCopy} from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment';

const AllOrdersPage: React.FC = () => {
  const [orders, setOrders] = useState<OrderSummaryDTO[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  // 주문 검색을 위한 상태
  const [orderSearchType, setOrderSearchType] = useState<string>('userId');
  const [searchValue, setSearchValue] = useState<string>('');

  // 타입 및 상태 선택 상태 추가
  const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(undefined);

  // 회원 검색을 위한 상태
  const [isUserSearchOpen, setIsUserSearchOpen] = useState(false);
  const [userSearchType, setUserSearchType] = useState<string>('nameOrEmail');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<CustomerBasicInfoDTO[]>([]);
  const [userSearchPage, setUserSearchPage] = useState(0);
  const [userSearchRowsPerPage, setUserSearchRowsPerPage] = useState(5);
  const [totalUsers, setTotalUsers] = useState(0);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderSummaryDTO | null>(null);

  // 날짜 선택 상태 (시작일, 종료일)
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(3, 'month'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders(page, rowsPerPage, startDate?.toISOString(), endDate?.toISOString());
  }, [page, rowsPerPage]);

  const fetchOrders = async (page: number, size: number, startDate?: string, endDate?: string) => {
    setIsLoading(true); // 로딩 시작
    const ordersApi = new OrdersApi();

    try {
      let response;

      // `orderId`로 검색하는 경우, 다른 조건들을 무시하고 `orderId`를 통해 정확히 검색
      if (orderSearchType === 'orderId' && searchValue) {
        response = await ordersApi.getOrders(
            null, // userId는 null
            page,
            size,
            searchValue, // 단일 orderId로 전달
            undefined, // 상태 무시
            undefined, // 타입 무시
            undefined, // 시작 날짜 무시
            undefined  // 종료 날짜 무시
        );
      } else {
        // 다른 조건으로 검색
        const modifiedStartDate = dayjs(startDate).startOf('day').toISOString();
        const modifiedEndDate = dayjs(endDate).endOf('day').toISOString();

        response = await ordersApi.getOrders(
            orderSearchType === 'userId' && searchValue ? Number(searchValue) : null,
            page,
            size,
            undefined, // orderId는 undefined로 설정
            selectedStatus ? [selectedStatus] : undefined,
            selectedType,
            modifiedStartDate,
            modifiedEndDate
        );
      }

      if (!response.items || response.items.length === 0) {
        setOrders([]);
        setTotalOrders(0);
      } else {
        setOrders(response.items);
        setTotalOrders(response.totalCount || 0);
      }
    } catch (error) {
      alert('주문 정보 조회 중 문제가 발생하였습니다');
      setOrders([]);
      setTotalOrders(0);
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  const handleOrderSearchTypeChange = (event: SelectChangeEvent<string>) => {
    setOrderSearchType(event.target.value as string);
    setSearchValue('');
  };

  const handleOrderSearch = () => {
    // 검색어와 선택된 상태, 타입을 함께 조회
    setPage(0);
    fetchOrders(0, rowsPerPage, startDate?.toISOString(), endDate?.toISOString());
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchOrders(newPage, rowsPerPage, startDate?.toISOString(), endDate?.toISOString());
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0);
    fetchOrders(0, newSize, startDate?.toISOString(), endDate?.toISOString());
  };

  const handleDetailClick = (orderId: string, userId: number, status: string) => {
    navigate(`/orders/detail/${orderId}/${userId}/${status}`);
  };

  const handleOpenDeleteDialog = (order: OrderSummaryDTO) => {
    setSelectedOrder(order);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedOrder(null);
  };

  const handleDeleteOrder = async () => {
    if (selectedOrder) {
      try {
        const ordersApi = new OrdersApi();
        await ordersApi.deleteOrder(selectedOrder.orderId);
        setIsDeleteDialogOpen(false);
        alert('주문이 삭제 되었습니다.');
        fetchOrders(page, rowsPerPage);
        window.location.reload();
      } catch (error) {
        alert('주문 삭제에 실패했습니다.');
      }
    }
  };

  const handleOpenUserSearch = () => {
    setSearchQuery('');
    setSearchResults([]);
    setIsUserSearchOpen(true);
  };

  const handleCloseUserSearch = () => {
    setIsUserSearchOpen(false);
  };

  // 회원 찾기 관련 함수들
  const handleSearchUsers = async () => {
    if (!searchQuery.trim()) {
      alert('검색값을 입력해 주세요.');
      return;
    }

    try {
      const usersApi = new UsersApi();
      const response = await usersApi.searchAdminUsers(
          userSearchPage, // 현재 페이지를 사용하여 검색
          userSearchRowsPerPage,
          userSearchType,
          searchQuery
      );

      setSearchResults(response.items);
      setTotalUsers(response.totalCount);
    } catch (error) {
      alert('회원 검색 중 오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    // 페이지 번호 변경 또는 페이지당 행 수 변경 시 회원 검색 호출
    if (isUserSearchOpen) {
      handleSearchUsers();
    }
  }, [userSearchPage, userSearchRowsPerPage]);

  const handleUserSearchChangePage = (event: unknown, newPage: number) => {
    setUserSearchPage(newPage);
  };

  const handleUserSearchChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(event.target.value, 10);
    setUserSearchRowsPerPage(newSize);
    setUserSearchPage(0); // 페이지 번호를 첫 페이지로 초기화
  };

  const handleUserSelect = (selectedUserId: number) => {
    setSearchValue(selectedUserId.toString());
    setIsUserSearchOpen(false);
  };

  // 타입 선택 핸들러
  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedType(event.target.value === 'ALL' ? undefined : event.target.value);
  };

  // 상태 선택 핸들러
  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value === 'ALL' ? undefined : event.target.value);
  };

  const formatDate = (dateArray: number[]) => {
    // 배열 길이가 7(마이크로초 포함된 경우), 배열 길이가 6(초까지 조회됨), 배열 길이가 5(분까지만 조회됨)
    if (dateArray.length === 7) {
      // 마이크로초 값은 무시하고 6자리까지만 사용
      const [year, month, day, hour, minute, second] = dateArray.slice(0, 6);
      return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
    } else if (dateArray.length === 6) {
      const [year, month, day, hour, minute, second] = dateArray;
      return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
    } else if (dateArray.length === 5) {
      const [year, month, day, hour, minute] = dateArray;
      return new Date(year, month - 1, day, hour, minute, 0).toLocaleString(); // 초는 0으로 설정
    } else {
      // 기본 처리 또는 오류 방지 로직
      return "유효하지 않은 날짜 형식입니다.";
    }
  };

  const renderDate = (date: any) => {
    if (Array.isArray(date)) {
      return formatDate(date);
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleString();
    } else {
      return date;
    }
  };

  const handleKeyPress = (action: () => void) => (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      action();
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleSearchButtonClick = () => {
    setUserSearchPage(0); // 새로운 검색 시 페이지 초기화
    handleSearchUsers(); // 검색 호출
  };

  // 클립보드에 주문 ID 복사 함수
  const copyOrderIdToClipboard = (orderId: string) => {
    navigator.clipboard.writeText(orderId)
    .then(() => {
      alert(`주문 ID ${orderId}이 클립보드에 복사되었습니다.`);
    })
    .catch((error) => {
      alert('클립보드 복사에 실패했습니다.');
    });
  };

  return (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" component="h5" style={{marginRight: '1rem'}}>
              주문 리스트
            </Typography>
            <Button variant="outlined" color="primary" onClick={handleRefresh}>
              초기화
            </Button>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center">
            <FormControl variant="outlined" size="small" style={{marginRight: '1rem'}}>
              <InputLabel id="order-search-type-label">검색조건</InputLabel>
              <Select
                  labelId="order-search-type-label"
                  value={orderSearchType}
                  onChange={handleOrderSearchTypeChange}
                  label="검색조건"
                  style={{minWidth: '100px'}}
              >
                <MenuItem value="userId">회원 ID</MenuItem>
                <MenuItem value="orderId">주문 ID</MenuItem>
              </Select>
            </FormControl>
            <TextField
                label={orderSearchType === 'userId' ? '회원 ID로 검색' : '주문 ID로 검색'}
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={handleKeyPress(handleOrderSearch)}
                size="small"
                style={{marginRight: '1rem'}}
                InputProps={{
                  endAdornment: orderSearchType === 'userId' ? (  // 회원 ID일 때만 버튼이 표시됨
                      <InputAdornment position="end">
                        <Button variant="contained" color="primary" style={{opacity: 0.7}}
                                onClick={handleOpenUserSearch}>
                          ID찾기
                        </Button>
                      </InputAdornment>
                  ) : null,  // 주문 ID일 때는 버튼이 표시되지 않음
                }}
            />
            <Button variant="contained" color="secondary" onClick={handleOrderSearch}>
              주문목록 조회
            </Button>
          </Box>

          {/* 타입 및 상태 선택 필드 */}
          <Box display="flex" alignItems="center">
            <Typography style={{marginRight: '0.5rem', fontSize: '1.2rem'}}>검색조건:</Typography>
            <FormControl variant="outlined" size="small" style={{marginRight: '1rem'}}>
              <InputLabel id="type-label">상품종류</InputLabel>
              <Select
                  labelId="type-label"
                  value={selectedType || 'ALL'}
                  onChange={handleTypeChange}
                  label="상품종류"
                  style={{minWidth: '120px'}}
              >
                <MenuItem value="ALL">전체</MenuItem>
                <MenuItem value="MEMBERSHIP">MEMBERSHIP</MenuItem>
                <MenuItem value="PREMIUM">PREMIUM</MenuItem>
                <MenuItem value="PREMIUMBUNDLE">PREMIUMBUNDLE</MenuItem>
                <MenuItem value="PREMIUMCUSTOM">PREMIUMCUSTOM</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" style={{marginRight: '1rem'}}>
              <InputLabel id="status-label">주문상태</InputLabel>
              <Select
                  labelId="status-label"
                  value={selectedStatus || 'ALL'}
                  onChange={handleStatusChange}
                  label="주문상태"
                  style={{minWidth: '120px'}}
              >
                <MenuItem value="ALL">전체</MenuItem>
                <MenuItem value="ORDERED">ORDERED</MenuItem>
                <MenuItem value="RESERVED">RESERVED</MenuItem>
                <MenuItem value="CANCELED">CANCELED</MenuItem>
                <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                <MenuItem value="FAILED">FAILED</MenuItem>
              </Select>
            </FormControl>

            {/* 시작일과 종료일 입력 필드 */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                  label="조회 시작일"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      size: "small",
                      style: {marginRight: "1rem"}
                    }
                  }}
              />
              <DatePicker
                  label="조회 종료일"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      size: "small",
                      style: {marginRight: "1rem"}
                    }
                  }}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>주문 ID</TableCell>
                  <TableCell>회원 ID</TableCell>
                  <TableCell>회원 이름</TableCell>
                  <TableCell>주문금액</TableCell>
                  <TableCell>할인</TableCell>
                  <TableCell>상품가격</TableCell>
                  <TableCell>주문 일시</TableCell>
                  <TableCell>상품종류</TableCell>
                  <TableCell>주문상태</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        주문 목록을 불러오는 중입니다...
                      </TableCell>
                    </TableRow>
                ) : orders.length > 0 ? (
                    orders.map((order) => (
                        <TableRow key={order.orderId} style={{cursor: 'pointer'}}>
                          <TableCell>
                            {order.orderId}
                            <Tooltip title="클립보드에 복사">
                        <span> {/* span으로 감싸서 children 타입 오류 해결 */}
                          <IconButton
                              onClick={() => copyOrderIdToClipboard(order.orderId)}
                              size="small"
                              color="primary"
                          >
                            <ContentCopy/>
                          </IconButton>
                        </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{order.userId.toString()}</TableCell>
                          <TableCell>{order.user?.name || '삭제된 회원입니다'}</TableCell>
                          <TableCell>{order.orderedPrice.toLocaleString()}</TableCell>
                          <TableCell>{order.discount.toLocaleString()}</TableCell>
                          <TableCell>{order.price.toLocaleString()}</TableCell>
                          <TableCell>{renderDate(order.orderedAt)}</TableCell>
                          <TableCell>{order.item?.type || 'N/A'}</TableCell>
                          <TableCell>{order.status}</TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => handleDetailClick(order.orderId, order.userId, order.status)}
                              >
                                상세조회
                              </Button>
                              <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => handleOpenDeleteDialog(order)}
                                  style={{marginLeft: '0.5rem'}}
                              >
                                삭제
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        검색된 주문이 없습니다.
                      </TableCell>
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalUsers} // 전체 유저 수를 사용하여 페이지네이션 처리
              rowsPerPage={userSearchRowsPerPage}
              page={userSearchPage} // 현재 페이지 상태
              onPageChange={handleUserSearchChangePage}
              onRowsPerPageChange={handleUserSearchChangeRowsPerPage}
          />
        </Paper>

        {/* 회원찾기 모달 팝업 */}
        <Dialog open={isUserSearchOpen} onClose={handleCloseUserSearch} maxWidth="sm" fullWidth>
          <DialogTitle>회원 찾기</DialogTitle>
          <DialogContent style={{maxHeight: '400px'}} dividers>
            <Box display="flex" mb={2}>
              <FormControl variant="outlined" size="small"
                           style={{marginRight: '1rem', minWidth: '150px'}}>
                <InputLabel id="user-search-type-label">검색조건</InputLabel>
                <Select
                    labelId="user-search-type-label"
                    value={userSearchType}
                    onChange={(e) => setUserSearchType(e.target.value)}
                    label="검색조건"
                >
                  <MenuItem value="name">이름</MenuItem>
                  <MenuItem value="email">이메일</MenuItem>
                  <MenuItem value="phoneNumber">전화번호</MenuItem>
                </Select>
              </FormControl>
              <TextField
                  label={
                    userSearchType === 'phoneNumber' ? '전화번호로 검색' :
                        userSearchType === 'name' ? '이름으로 검색' :
                            '이메일로 검색'
                  }
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleKeyPress(handleSearchUsers)}
                  size="small"
                  style={{maxWidth: '300px'}}
              />
              <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearchButtonClick} // handleSearchUsers 대신 handleSearchButtonClick 사용
                  style={{marginLeft: '1rem'}}
              >
                찾기
              </Button>

            </Box>
            <TableContainer>
              <Table>
                <TableBody>
                  {searchResults.map((user) => (
                      <TableRow key={user.userId}>
                        <TableCell>{user.userId}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phoneNumber}</TableCell>
                        <TableCell>
                          <Button variant="contained" color="primary"
                                  onClick={() => handleUserSelect(user.userId)}>
                            선택
                          </Button>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalUsers} // 전체 유저 수를 사용하여 페이지네이션 처리
                rowsPerPage={userSearchRowsPerPage}
                page={userSearchPage} // 현재 페이지 상태
                onPageChange={handleUserSearchChangePage}
                onRowsPerPageChange={handleUserSearchChangeRowsPerPage}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUserSearch} color="secondary">
              닫기
            </Button>
          </DialogActions>
        </Dialog>

        {/* 주문 삭제 모달 팝업 */}
        <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog} maxWidth="sm" fullWidth>
          <DialogTitle>주문 삭제 확인</DialogTitle>
          <DialogContent>
            {selectedOrder && (
                <>
                  <Typography>주문 ID: {selectedOrder.orderId}</Typography>
                  <Typography>회원 이름: {selectedOrder.user?.name}</Typography>
                  <Typography>주문 일시: {renderDate(selectedOrder.orderedAt)}</Typography>
                  <Typography color="error">정말로 이 주문을 삭제하시겠습니까?</Typography>
                </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              취소
            </Button>
            <Button onClick={handleDeleteOrder} color="secondary">
              삭제
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default AllOrdersPage;
