import HomePage from '../pages/home/HomePage'
import { RouteType } from './config'
import ComponentPageLayout from '../pages/component/ComponentPageLayout'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import AlertPage from '../pages/component/AlertPage'
import ButtonPage from '../pages/component/ButtonPage'
import DocumentationPage from '../pages/documentation/DocumentationPage'
import OrdersPageLayout from '../pages/orders/OrdersPageLayout'
import CreateOrderPage from '../pages/orders/CreateOrderPage'
import DashboardPage from '../pages/dashboard/DashboardPage'
import LoginPage from '../pages/auth/LoginPage'
import CouponsPageLayout from '../pages/coupons/CouponsPageLayout'
import CreateCouponPage from '../pages/coupons/CreateCouponPage'
import RegisterCouponPage from '../pages/coupons/RegisterCouponPage'
import { Person } from '@mui/icons-material'
import UsersListsPage from '../pages/users/UsersListsPage'
import UsersPageLayout from '../pages/users/UsersPageLayout'
import PushNotificationPageLayout from '../pages/push_notification/PushNotificationPageLayout'
import PushNotificationListPage from '../pages/push_notification/PushNotificationListPage'
import PushNotificationFormPage from '../pages/push_notification/PushNotificationCreatePage'
import PushNotificationUpdatePage from '../pages/push_notification/PushNotificationUpdatePage'
import UserRoleUpdatePage from '../pages/users/UserRoleUpdatePage'
import UserUpdatePage from '../pages/users/MembershipUpdatePage'
import MembershipUpdatePage from '../pages/users/MembershipUpdatePage'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import AllUsersListsPage from '../pages/users/AllUsersListsPage'
import UserDetailsPage from '../pages/users/UserDetailsPage'
import DeactivatedUsersPage from '../pages/users/DeactivatedUsersPage'
import DeactivationStatisticsPage from '../pages/users/DeactivationStatisticsPage'
import AllSubscribedUsersPage from '../pages/users/AllSubscribedUsersPage'
import UserCreatePage from '../pages/users/UserCreatePage'
import OdersPage from '../pages/orders/AllOrdersPage'
import AllOrdersPage from '../pages/orders/AllOrdersPage'
import OrderDetailPage from '../pages/orders/OrderDetailPage'
import TicketPage from '../pages/orders/TicketsPage'
import TicketsPage from '../pages/orders/TicketsPage'
import ModalPageLayout from '../pages/modal/ModalPageLayout'
import ModalCreatePageView from '../pages/modal/ModalCreatePageView'
import ModalListPage from '../pages/modal/ModalListPage'
import LightRequestLogListPage from '../pages/lightRequestLog/LightRequestLogListPage'
import LigthRequestLogPageLayout from '../pages/lightRequestLog/LightRequestLogPageLayout'
import PremiumRequestLogListPage from '../pages/premiumRequestLog/PremiumRequestLogListPage'
import PremiumRequestLogPageLayout from '../pages/premiumRequestLog/PremiumRequestLogPageLayout'
import RequestLogPageLayout from '../pages/requestLog/RequestLogPageLayout'

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <DashboardPage />,
    state: 'dashboard',
  },
  {
    index: true,
    path: '/dashboard',
    element: <DashboardPage />,
    state: 'dashboard',
    sidebarProps: {
      displayText: 'Dashboard',
      icon: <DashboardOutlinedIcon />,
    },
  },
  {
    path: '/users',
    element: <UsersPageLayout />,
    state: 'users',
    sidebarProps: {
      displayText: '회원',
      icon: <Person />,
    },
    child: [
      {
        path: '/users/lists',
        element: <UsersListsPage />,
        state: 'users.lists',
      },
      {
        path: '/users/all',
        element: <AllUsersListsPage />,
        state: 'users.all',
        sidebarProps: {
          displayText: '가입회원 전체조회',
        },
      },
      {
        path: '/users/create',
        element: <UserCreatePage />,
        state: 'createAccount', // 고유한 state 값 설정
        sidebarProps: {
          displayText: '계정 생성',
        },
      },
      {
        path: '/users/subscribed',
        element: <AllSubscribedUsersPage />,
        state: 'users.subscribed',
      },
      {
        path: '/users/details/:userId',
        element: <UserDetailsPage />,
        state: 'users.all.details',
      },
    ],
  },
  {
    path: '/user/membership/:userId',
    element: <MembershipUpdatePage />,
    state: 'users.form',
    // sidebarProps: {
    //   displayText: "알림 수정"
    // },
  },
  // {
  //   path: "/user/role/:userId",
  //   element: <UserRoleUpdatePage />,
  //   state: "users.role.form",
  // sidebarProps: {
  //   displayText: "알림 수정"
  // },
  // },
  {
    path: '/users/:userId/tickets',
    element: <TicketsPage />,
    state: 'user.tickets',
  },

  {
    path: '/orders',
    element: <OrdersPageLayout />,
    state: 'orders',
    sidebarProps: {
      displayText: '주문',
      icon: <AppsOutlinedIcon />,
    },
    child: [
      {
        path: '/orders/create',
        element: <CreateOrderPage />,
        state: 'orders.create',
        sidebarProps: {
          displayText: '주문 생성',
        },
      },
      {
        path: '/orders/search',
        element: <AllOrdersPage />,
        state: 'orders.search',
        sidebarProps: {
          displayText: '주문 조회',
        },
      },

      {
        path: '/orders/detail/:orderId/:userId/:status',
        element: <OrderDetailPage />,
        state: 'orders.detail',
      },
    ],
  },
  {
    path: '/coupons',
    element: <CouponsPageLayout />,
    state: 'coupons',
    sidebarProps: {
      displayText: '쿠폰',
      icon: <AppsOutlinedIcon />,
    },
    child: [
      {
        path: '/coupons/create',
        element: <CreateCouponPage />,
        state: 'coupons.create',
        sidebarProps: {
          displayText: '쿠폰 생성',
        },
      },
      {
        path: '/coupons/register',
        element: <RegisterCouponPage />,
        state: 'coupons.register',
        sidebarProps: {
          displayText: '쿠폰 등록',
        },
      },
    ],
  },
  {
    path: '/push_notification',
    element: <PushNotificationPageLayout />,
    state: 'push_notification',
    sidebarProps: {
      displayText: '발송 관리',
      icon: <AppsOutlinedIcon />,
    },
    child: [
      {
        path: '/push_notification/list',
        element: <PushNotificationListPage />,
        state: 'push_notification.list',
        sidebarProps: {
          displayText: '알림 조회',
        },
      },
      {
        path: '/push_notification/form',
        element: <PushNotificationFormPage />,
        state: 'push_notification.form',
        // sidebarProps: {
        //   displayText: "알림 등록"
        // },
      },
      {
        path: '/push_notification/form/:id',
        element: <PushNotificationUpdatePage />,
        state: 'push_notification.form',
        // sidebarProps: {
        //   displayText: "알림 수정"
        // },
      },
    ],
  },
  {
    path: '/deactivated-users',
    element: <UsersPageLayout />,
    state: 'deactivatedUsers',
    sidebarProps: {
      displayText: '탈퇴회원관리',
      icon: <Person />,
    },
    child: [
      {
        path: '/deactivated-users/statistics',
        element: <DeactivationStatisticsPage />,
        state: 'deactivatedUsers.statistics',
        sidebarProps: {
          displayText: '탈퇴회원 통계',
        },
      },
    ],
  },
  {
    path: '/modal',
    element: <ModalPageLayout />,
    state: 'modal',
    sidebarProps: {
      displayText: '모달',
      icon: <AppsOutlinedIcon />,
    },
    child: [
      {
        path: '/modal/list',
        element: <ModalListPage />,
        state: 'modal.list',
        sidebarProps: {
          displayText: '모달 조회',
        },
      },
      {
        path: '/modal/create',
        element: <ModalCreatePageView />,
        state: 'modal.create',
        sidebarProps: {
          displayText: '모달 생성',
        },
      },
    ],
  },
  // {
  //   path: "/component",
  //   element: <ComponentPageLayout />,
  //   state: "component",
  //   sidebarProps: {
  //     displayText: "Components",
  //     icon: <AppsOutlinedIcon />
  //   },
  //   child: [
  //     {
  //       path: "/component/alert",
  //       element: <AlertPage />,
  //       state: "component.alert",
  //       sidebarProps: {
  //         displayText: "Alert"
  //       },
  //     },
  //     {
  //       path: "/component/button",
  //       element: <ButtonPage />,
  //       state: "component.button",
  //       sidebarProps: {
  //         displayText: "Button"
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: "/documentation",
  //   element: <DocumentationPage />,
  //   state: "documentation",
  //   sidebarProps: {
  //     displayText: "Documentation",
  //     icon: <ArticleOutlinedIcon />
  //   }
  // },
  {
    path: '/request/logs',
    element: <RequestLogPageLayout />,
    state: 'request.logs',
    sidebarProps: {
      displayText: '설계 요청',
      icon: <ArticleOutlinedIcon />,
    },
    child: [
      {
        path: '/request/logs/light',
        element: <LightRequestLogListPage />,
        state: 'request.logs.light',
        sidebarProps: {
          displayText: '라이트 분석',
        },
      },
      {
        path: '/request/logs/premium',
        element: <PremiumRequestLogListPage />,
        state: 'request.logs.premium',
        sidebarProps: {
          displayText: '플러스 분석',
        },
      },
    ],
  },
  // {
  //   path: "/component",
  //   element: <ComponentPageLayout />,
  //   state: "component",
  //   sidebarProps: {
  //     displayText: "Components",
  //     icon: <AppsOutlinedIcon />
  //   },
  //   child: [
  //     {
  //       path: "/component/alert",
  //       element: <AlertPage />,
  //       state: "component.alert",
  //       sidebarProps: {
  //         displayText: "Alert"
  //       },
  //     },
  //     {
  //       path: "/component/button",
  //       element: <ButtonPage />,
  //       state: "component.button",
  //       sidebarProps: {
  //         displayText: "Button"
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: "/documentation",
  //   element: <DocumentationPage />,
  //   state: "documentation",
  //   sidebarProps: {
  //     displayText: "Documentation",
  //     icon: <ArticleOutlinedIcon />
  //   }
  // },
]

export default appRoutes
