export enum ModalType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  NOTICE = 'NOTICE',
}

export interface ModalDTO {
  id: number
  title: string
  content: string
  modalType: ModalType
  display: boolean
  order: number
  createdAt: string
  updatedAt: string
}

export interface ModalCreateDTO {
  title: string
  content: string
  modalType: ModalType
  display: boolean
}

export interface ModalUpdateOrderedRequestDTO {
  modalIds: number[]
}

export interface ModalResponseDTO {
  id: number
  title: string
  content: string
  modalType: ModalType
  display: boolean
  order: number
  createdAt: [number, number, number, number, number, number, number]
  updatedAt: [number, number, number, number, number, number, number]
}
