import {
  Container,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import { useEffect, useState } from "react";
import PushNotificationApi from "../../modules/push_notification";
import { useNavigate, useParams } from "react-router-dom";
import { CreatePushNotificationModel, PushNotificationModel } from "../../model/PushNotificationModel";

const PushNotificationUpdatePage = () => {
  const navigate = useNavigate();
  const api = new PushNotificationApi();
  const [formData, setFormData] = useState<PushNotificationModel>({
    id: 0,
    title: "",
    link: "",
    contents: "",
    pushedAt: "",
    memo: "",
    type: "",
    createdAt: "",
    updatedAt: "",
    reservationAt: "",
  });

  const { id } = useParams();

  const fetchPushNotification = async (id: string) => {
    const api = new PushNotificationApi();
    const pushNotification = await api.getPushNotification(id);
    console.log(pushNotification);
    if (pushNotification) {
      setFormData(pushNotification);
    }
  };
  useEffect(() => {
    fetchPushNotification(id!!);
  }, [id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value || "",
    }));
  };

  const [selectedDate, setSelectedDate] = useState<string>();
  const handleDateChange = (date: Dayjs | null) => {
    if (date != null) {
      const formatDate = date.format("YYYY-MM-DD HH:mm:ss");
      setSelectedDate(formatDate);
      console.log("formatDate");
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedDate !== undefined) {
      formData.reservationAt = selectedDate;
    }

    try {
      const updateFormData = {
        title: formData.title,
        link: formData.link,
        contents: formData.contents,
        memo: formData.memo,
        type: formData.type,
        reservationAt: formData.reservationAt ? formData.reservationAt : "",
      }
      const res = await api.updatePushNotification(id!!, updateFormData);
      if ("status" in res) {
        const responseData = res.data;
        // Now you can work with responseData
        console.log("responseData", responseData);
        if (res.status === 200) {
          alert("수정되었습니다.");
          window.location.reload();
        }
      } else {
        alert("오류가 발생했습니다. 관리자에게 문의해주세요.");
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleListPage = () => {
    navigate("/push_notification/list");
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 4, p: 2 }}>
        <CardContent>
          <Box>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ mb: 2 }}
            >
              푸쉬 알림 수정
            </Typography>
            <FormGroup sx={{ mb: 2 }}>
              {/* <FormLabel component="legend">제목</FormLabel> */}
              <TextField
                label="제목"
                variant="outlined"
                name="title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                required
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>타입</InputLabel>
                <Select
                  label="타입 선택"
                  name="type"
                  value={formData.type}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="marketing">마케팅</MenuItem>
                  {/* <MenuItem value="option2">Option 2</MenuItem> */}
                  {/* <MenuItem value="option3">Option 3</MenuItem> */}
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <TextField
                  id="contents"
                  name="contents"
                  label="내용"
                  fullWidth
                  multiline
                  rows={4}
                  value={formData.contents}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <TextField
                label="URL 링크"
                variant="outlined"
                name="link"
                value={formData.link}
                onChange={handleChange}
                fullWidth
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <TextField
                label="메모"
                variant="outlined"
                name="memo"
                value={formData.memo}
                onChange={handleChange}
                fullWidth
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="예약날짜 선택"
                  value={
                    formData.reservationAt
                      ? dayjs(formData.reservationAt)
                      : null
                  }
                  onChange={handleDateChange}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </FormGroup>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 3, ml: 1 }}
                onClick={handleSubmit}
              >
                수정
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 3, ml: 1 }}
                onClick={handleListPage}
              >
                목록
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PushNotificationUpdatePage;
