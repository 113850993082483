/**
 * 인증 관련 (로그인)
 */
import Api from '../configs/apiConfigs'
import * as tokenUtils from './tokenInfo'

export default class AuthApi extends Api {
  async login(userId: string, password: string) {
    const params = {
      email: userId,
      password: password,
    }

    try {
      const res = await super.post(
        `${process.env.REACT_APP_API_URL}/auth/signin`,
        JSON.stringify(params)
      )
      const user = res.data as { token: string }
      if (user.token) {
        const tokenInfo = tokenUtils.getTokenInfo(user.token)
        console.log(tokenInfo)
        if (tokenInfo?.roles.includes('ROLE_ADMIN')) {
          const refreshToken = user.token.split(' ')[1]
          sessionStorage.setItem('accessToken', user.token)
          sessionStorage.setItem('refreshToken', refreshToken)
          const formattedExpirationTime = tokenUtils
            .convertToKST(tokenInfo.exp)
            .toLocaleString('en-US')
          sessionStorage.setItem('expireAt', formattedExpirationTime)
          console.log(sessionStorage)
          return user.token
        } else {
          alert('접근 권한이 없는 계정입니다.')
          return
        }
      } else {
        const error = res.data as { result: { code: number; msg: string } }
        alert(error.result.msg)
      }
    } catch (error) {
      // const error = res.data as { result: { code: number; msg: string } };
      // alert(error.result.msg);
    }
  }

  async refresh() {
    await super
      .put(`${process.env.REACT_APP_API_URL}/auth/refresh`)
      .then(function (res) {
        const user = res.data as { token: string }
        if (user.token) {
          const tokenInfo = tokenUtils.getTokenInfo(user.token)
          console.log(tokenInfo)
          if (tokenInfo?.roles.includes('ROLE_ADMIN')) {
            sessionStorage.setItem('accessToken', user.token)
            sessionStorage.setItem('refreshToken', user.token)
            const formattedExpirationTime = tokenUtils
              .convertToKST(tokenInfo.exp)
              .toLocaleString('en-US')
            sessionStorage.setItem('expireAt', formattedExpirationTime)
          } else {
            return
          }

          return user.token
        }
      })
  }

  logout() {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.removeItem('expireAt')
    // 추가적으로 서버에 로그아웃 요청을 보내는 경우, 여기서 처리 가능
    // 예를 들어, 서버에 POST 요청으로 세션 만료 처리를 요청할 수 있음
    // axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`);
    window.location.replace('/login')
  }
}
