import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import {CreateAdminPaymentReq, OrderSummaryDTO} from '../../model/OrderModel';
import PaymentApi from '../../modules/payment';
import TicketApi from "../../modules/ticket";
import {RequestTicketDTO} from "../../model/TicketModel";
import {useNavigate} from "react-router-dom";

interface PaymentFormProps {
  formData: CreateAdminPaymentReq;
  setFormData: React.Dispatch<React.SetStateAction<CreateAdminPaymentReq>>;
  orderId: string;
  orderData: OrderSummaryDTO | null;
  userId: number;
  onClose: () => void;
  isEditMode: boolean; // 결제정보 수정인지 생성인지 여부를 나타내는 플래그
}

const paymentMethods = ['CARD', 'VBANK', 'MOBILE', 'TRANS', 'POINT', 'INAPP', 'COUPON', 'UNKNOWN'];
const paymentStatuses = ['READY', 'PAID', 'FAILED', 'CANCELLED', 'REFUND_REQUIRED', 'UNKNOWN', 'PARTIAL_CANCELLED'];
const vans = ['IMP', 'APPLE', 'GOOGLE', 'SWK', 'UNKNOWN'];

// 영어 -> 한글 매핑
const paymentMethodLabels: Record<string, string> = {
  CARD: '카드',
  VBANK: '가상계좌',
  MOBILE: '모바일',
  TRANS: '계좌이체',
  POINT: '포인트',
  INAPP: '인앱',
  COUPON: '쿠폰',
  UNKNOWN: '알 수 없음',
};

const paymentStatusLabels: Record<string, string> = {
  READY: '준비중',
  PAID: '결제 완료',
  FAILED: '결제 실패',
  CANCELLED: '결제 취소',
  REFUND_REQUIRED: '환불 요청',
  UNKNOWN: '알 수 없음',
  PARTIAL_CANCELLED: '부분 취소',
};

const vanLabels: Record<string, string> = {
  IMP: '아임포트',
  APPLE: '애플',
  GOOGLE: '구글',
  SWK: 'SWK',
  UNKNOWN: '알 수 없음',
};

const PaymentForm: React.FC<PaymentFormProps> = ({ formData, setFormData, orderId, orderData, userId, onClose, isEditMode }) => {
  const navigate = useNavigate(); // useNavigate 훅 추가

  const handleSubmit = async () => {
    try {
      const paymentApi = new PaymentApi();

      if (isEditMode) {
        // 결제 정보가 있으면 업데이트
        await paymentApi.updatePayment(userId, orderId, formData);
      } else {
        // 결제 정보가 없으면 새로 생성
        await paymentApi.createPayment(userId, orderId, formData);
      }

        // 결제 상태가 PAID이고, 오더 아이디에 티켓이 없는 경우 티켓 생성
      if (formData.paidStatus === 'PAID' ) {
        const ticketApi = new TicketApi()

        const response = await ticketApi.getTicketsByOrderId(orderId);

        // RequestTicketDTO 생성
        if (!response || response.length === 0){
        const requestTicketDTO: RequestTicketDTO = {
          userId: userId,
          orderId: orderId,
        };
        // 티켓 생성 호출
        await ticketApi.createTickets(requestTicketDTO);
        }
      }

      navigate(`/orders/detail/${orderId}/${userId}/COMPLETED`, {replace: true});

      alert('결제가 성공적으로 처리되었습니다.');
      onClose(); // 모달 닫기
    } catch (error) {
      alert('결제 처리에 실패했습니다.');
    }
  };

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
              label="결제 금액"
              variant="outlined"
              type="number"
              value={formData.amount}
              onChange={(e) => setFormData({ ...formData, amount: Number(e.target.value) })}
              fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>결제 방법</InputLabel>
            <Select
                value={formData.method || ''}
                onChange={(e) => setFormData({ ...formData, method: e.target.value })}
                label="결제 방법"
            >
              {paymentMethods.map((method) => (
                  <MenuItem key={method} value={method}>
                    {paymentMethodLabels[method]}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>결제 상태</InputLabel>
            <Select
                value={formData.paidStatus || ''}
                onChange={(e) => setFormData({ ...formData, paidStatus: e.target.value })}
                label="결제 상태"
            >
              {paymentStatuses.map((status) => (
                  <MenuItem key={status} value={status}>
                    {paymentStatusLabels[status]}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>결제 대행</InputLabel>
            <Select
                value={formData.type || ''}
                onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                label="VAN"
            >
              {vans.map((van) => (
                  <MenuItem key={van} value={van}>
                    {vanLabels[van]}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
              label="가상계좌 은행 코드"
              variant="outlined"
              value={formData.vbankCode || ''}
              onChange={(e) => setFormData({ ...formData, vbankCode: e.target.value })}
              fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              label="가상계좌 은행 이름"
              variant="outlined"
              value={formData.vbankName || ''}
              onChange={(e) => setFormData({ ...formData, vbankName: e.target.value })}
              fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              label="가상계좌 계좌번호"
              variant="outlined"
              value={formData.vbankNum || ''}
              onChange={(e) => setFormData({ ...formData, vbankNum: e.target.value })}
              fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              label="가상계좌 예금주"
              variant="outlined"
              value={formData.vbankHolder || ''}
              onChange={(e) => setFormData({ ...formData, vbankHolder: e.target.value })}
              fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {isEditMode ? '결제정보 수정' : '결제정보 생성'}
          </Button>
        </Grid>
      </Grid>
  );
};

export default PaymentForm;
