import React from 'react'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Box,
  Radio,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { ModalDTO, ModalType } from '../../model/ModalModel'
import styles from '../../style/modal/ModalDetailPage.module.css'

interface ModalDetailEditProps {
  modal: ModalDTO
  onChange: (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | SelectChangeEvent<ModalType>
  ) => void
}

const ModalUpdateView: React.FC<ModalDetailEditProps> = ({
  modal,
  onChange,
}) => {
  return (
    <>
      <Typography className={styles.label}>ID:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.id}</Typography>
      </Box>
      <Typography className={styles.label}>제목:</Typography>
      <Box className={styles.field}>
        <TextField
          name="title"
          value={modal.title}
          onChange={(e) => onChange(e as SelectChangeEvent<ModalType>)}
          fullWidth
        />
      </Box>
      <Typography className={styles.label}>내용:</Typography>
      <Box className={styles.field}>
        <TextField
          name="content"
          value={modal.content}
          onChange={onChange}
          fullWidth
          multiline
        />
      </Box>
      <Typography className={styles.label}>타입:</Typography>
      <Box className={styles.field}>
        <FormControl fullWidth>
          <Select
            name="modalType"
            value={modal.modalType}
            onChange={onChange}
            fullWidth
          >
            {Object.values(ModalType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography className={styles.label}>표시 여부:</Typography>
      <Box className={styles.field}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            name="display"
            value={modal.display.toString()}
            onChange={onChange}
          >
            <FormControlLabel value="true" control={<Radio />} label="예" />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="아니오"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Typography className={styles.label}>순서:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.order}</Typography>
      </Box>
      <Typography className={styles.label}>생성일:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.createdAt}</Typography>
      </Box>
      <Typography className={styles.label}>수정일:</Typography>
      <Box className={styles.field}>
        <Typography className={styles.value}>{modal.updatedAt}</Typography>
      </Box>
    </>
  )
}

export default ModalUpdateView
