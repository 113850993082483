import React, {useState} from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import UsersApi from '../../modules/users';
import {AdminSignUpReqDTO} from '../../model/UserModel';

const UserCreatePage: React.FC = () => {
  const [formData, setFormData] = useState<AdminSignUpReqDTO>({
    email: '',
    name: '',
    password: '',
    isAdmin: false,
    passwordConfirmation: '',
    phoneNumber: '',
    impUid: null, // impUid의 기본값을 null로 설정, 빈 문자열이면 impUID 가 존재하는 걸로 인식. 인증 유저가 생성
    birth: '',
    position: '',
    purposeOfFunnels: []
  });

  const handlePhoneNumberChange = (index: number, value: string) => {
    const phoneNumber = (formData.phoneNumber || '').split('-');
    phoneNumber[index] = value;
    setFormData({...formData, phoneNumber: phoneNumber.join('-')});
  };

  const handleBirthChange = (index: number, value: string) => {
    const birth = (formData.birth || '').split('-');
    birth[index] = value;
    setFormData({...formData, birth: birth.join('-')});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value, type} = e.target;

    if (type === 'checkbox') {
      const {checked} = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleImpUidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData({...formData, impUid: value === '' ? null : value});
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const prepareDataForSubmit = () => {
    const sanitizedFormData = {
      ...formData,
      phoneNumber: (formData.phoneNumber || '').replace(/-/g, ''), // 하이픈 제거
      impUid: formData.impUid == null || formData.impUid === '' ? "imp_0000" : formData.impUid // impUid 기본값 설정
    };

    return sanitizedFormData;
  };

  const handleSubmit = async () => {

    // 이름이 입력되지 않았을 때
    if (!formData.name) {
      alert('이름은 필수 입력 항목입니다.');
      return; // 입력되지 않으면 로직 중단
    }

    // 이메일이 입력되지 않았을 때
    if (!formData.email) {
      alert('이메일은 필수 입력 항목입니다.');
      return;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) { //이메일 형식 정규식
      alert('유효한 이메일 형식이 아닙니다.');
      return;
    }

    // 비밀번호가 입력되지 않았을 때
    if (!formData.password) {
      alert('비밀번호는 필수 입력 항목입니다.');
      return;
    }

    if(formData.password.length <= 5) {
      alert('비밀번호는 6자 이상으로 설정하세요.');
      return;
    }

    // 비밀번호 확인이 일치하지 않을 때
    if (formData.password !== formData.passwordConfirmation) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    // 전화번호가 입력되지 입력 되지 않았을 때
    if (!formData.phoneNumber) {
      alert('전화번호는 필수 입력 항목입니다.');
      return;
    }

    try {
      const usersApi = new UsersApi();
      const sanitizedFormData = prepareDataForSubmit();
      await usersApi.createUser(sanitizedFormData);
      alert('계정이 성공적으로 생성되었습니다!');
      window.location.reload();
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { msg } = error.response.data;
        alert(`${msg}`);
      } else {
        alert('계정생성에 실패 했습니다. 이미 사용하고 있는 이메일인지 확인하고 운영자에게 문의해주세요');
      }
    }
  };

  return (
      <Box display="flex" justifyContent="center" p={2}>
        <Grid container spacing={2} maxWidth="sm">
          <Grid item xs={12} md={8}>
            <Paper elevation={3} style={{padding: '16px'}}>
              <Typography variant="h6" align="center">계정 생성</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                      label="이름"
                      name="name"
                      fullWidth
                      variant="outlined"
                      value={formData.name}
                      onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      label="E-mail"
                      name="email"
                      fullWidth
                      variant="outlined"
                      value={formData.email}
                      onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      label="비밀번호(6자 이상 입력해주세요)"
                      name="password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={formData.password}
                      onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      label="비밀번호 확인"
                      name="passwordConfirmation"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={formData.passwordConfirmation}
                      onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            checked={formData.isAdmin}
                            onChange={handleChange}
                            name="isAdmin"
                            color="primary"
                        />
                      }
                      label="Admin 계정 여부"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">핸드폰 번호</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <TextField
                          fullWidth
                          value={formData.phoneNumber?.split('-')[0] || ''}
                          onChange={(e) => handlePhoneNumberChange(0, e.target.value)}
                          inputProps={{maxLength: 3}}
                          placeholder="010"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                          fullWidth
                          value={formData.phoneNumber?.split('-')[1] || ''}
                          onChange={(e) => handlePhoneNumberChange(1, e.target.value)}
                          inputProps={{maxLength: 4}}
                          placeholder="0000"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                          fullWidth
                          value={formData.phoneNumber?.split('-')[2] || ''}
                          onChange={(e) => handlePhoneNumberChange(2, e.target.value)}
                          inputProps={{maxLength: 4}}
                          placeholder="0000"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">생년월일</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <TextField
                          fullWidth
                          value={formData.birth?.split('-')[0] || ''}
                          onChange={(e) => handleBirthChange(0, e.target.value)}
                          inputProps={{maxLength: 4}}
                          placeholder="0000"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                          fullWidth
                          value={formData.birth?.split('-')[1] || ''}
                          onChange={(e) => handleBirthChange(1, e.target.value)}
                          inputProps={{maxLength: 2}}
                          placeholder="00"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                          fullWidth
                          value={formData.birth?.split('-')[2] || ''}
                          onChange={(e) => handleBirthChange(2, e.target.value)}
                          inputProps={{maxLength: 2}}
                          placeholder="00"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      label="impUid"
                      name="impUid"
                      fullWidth
                      variant="outlined"
                      value={formData.impUid || ''}
                      onChange={handleImpUidChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>직업</InputLabel>
                    <Select
                        name="position"
                        value={formData.position}
                        onChange={handleSelectChange}
                        label="직업"
                    >
                      <MenuItem value="개인투자자">개인투자자</MenuItem>
                      <MenuItem value="법인투자자">법인투자자</MenuItem>
                      <MenuItem value="부동산중개">부동산중개</MenuItem>
                      <MenuItem value="건축가">건축가</MenuItem>
                      <MenuItem value="시공사">시공사</MenuItem>
                      <MenuItem value="기타">기타</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>가입목적(복수선택가능)</InputLabel>
                    <Select
                        name="purposeOfFunnels"
                        multiple
                        value={formData.purposeOfFunnels}
                        onChange={(e) => setFormData({
                          ...formData,
                          purposeOfFunnels: e.target.value as string[]
                        })}
                        label="가입목적"
                    >
                      <MenuItem value="토지추정가">토지추정가</MenuItem>
                      <MenuItem value="신축 규모검토">신축 규모검토</MenuItem>
                      <MenuItem value="매물 정보">매물 정보</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                    계정 생성
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={1} style={{ padding: '16px', backgroundColor: '#f5f5f5' }}>
              <Typography variant="h6" color="textSecondary">
                ⚠️ 주의사항
              </Typography>
              <Typography variant="body2" color="textSecondary">
                - 이메일은 유효한 형식이어야 합니다.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                - 비밀번호는 6자 이상이어야 합니다.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                - 핸드폰 번호는 숫자만 입력해 주세요. 하이픈(-)은 자동으로 처리됩니다.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                - Admin 계정으로 선택을 하지 않으면 USER 계정으로 생성됩니다.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                - impUid는 입력하지 않으시면 임의의 값으로 생성됩니다.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                - 기업회원일 경우에는 반드시 '기업구독'으로 주문생성까지 완료해주세요.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
  );
};

export default UserCreatePage;
