/**
 * 회원 관련 API
 */

import Api from "../configs/apiConfigs";
import { PageItemModel } from "../model/PageItemModel";
import { CreatePushNotificationModel, PushNotificationModel } from "../model/PushNotificationModel";

export default class PushNotificationApi extends Api {
  async getPushNotificationList(page: number, size: number, query: string) {
    try {
      const response = await super.get(
        `${process.env.REACT_APP_API_URL}/auth/admin/push_notification/lists?page=${page+1}&size=${size}&query=${query}`
      );

      const responseData = response.data as PageItemModel<PushNotificationModel>;
      return responseData
    } catch (error) {
      console.error(error);
      return {
        currentPage: page,
        items: [],
        pageSize: size,
        totalCount: 0,
      };
    }
  }
  
  async getPushNotification(id: string) {
    try {
      const response = await super.get(
        `${process.env.REACT_APP_API_URL}/auth/admin/push_notification/${id}`,
      );
      const responseData = response.data as PushNotificationModel
      return responseData;
    } catch (error) {
      return null;
    }
  }

  async createPushNotification(formData: CreatePushNotificationModel) {
    try {
      const response = await super.post(
        `${process.env.REACT_APP_API_URL}/auth/admin/push_notification`,
        JSON.stringify(formData)
      );

      return response;
    } catch (error) {
      return [];
    }
  }

  async updatePushNotification(id: string, formData: CreatePushNotificationModel) {
    try {
      const response = await super.put(
        `${process.env.REACT_APP_API_URL}/auth/admin/push_notification/${id}`,
        JSON.stringify(formData)
      );

      return response;
    } catch (error) {
      return [];
    }
  }

}