import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  IconButton,
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import TicketApi from '../../modules/ticket';
import { TicketDTO } from '../../model/TicketModel';
import UpdateTicketModal from './UpdateTicketModal';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface TicketPageProps {
  orderId?: string;
  userId?: string;
}

const TicketPage: React.FC<TicketPageProps> = ({ orderId, userId }) => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<TicketDTO[]>([]);
  const [currentPageTickets, setCurrentPageTickets] = useState<TicketDTO[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<string>('createdAt');
  const [totalTickets, setTotalTickets] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<TicketDTO | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [bulkStatus, setBulkStatus] = useState<string>('AVAILABLE');
  const [bulkUsedAt, setBulkUsedAt] = useState<Dayjs | null>(null);
  const [selectedTickets, setSelectedTickets] = useState<string[]>([]); // 선택된 티켓 ID

  const ticketApi = new TicketApi();

  // 플러그인 설정
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // 서울 시간대 설정
  dayjs.tz.setDefault('Asia/Seoul');

  useEffect(() => {
    if (orderId || userId) {
      fetchTickets();
    }
  }, [orderId, userId]);

  useEffect(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    setCurrentPageTickets(
        [...tickets]
        .sort((a, b) => {
          const dateA = dayjs(a[sortColumn as keyof TicketDTO]);
          const dateB = dayjs(b[sortColumn as keyof TicketDTO]);
          if (sortOrder === 'asc') {
            return dateA.isAfter(dateB) ? 1 : -1;
          }
          return dateA.isBefore(dateB) ? 1 : -1;
        })
        .slice(start, end)
    );
  }, [page, rowsPerPage, tickets, sortOrder, sortColumn]);

  const fetchTickets = async (status?: string) => {
    setIsLoading(true);

    try {
      let response;
      if (orderId) {
        response = await ticketApi.getTicketsByOrderId(orderId, status ? [status] : undefined);
      } else if (userId) {
        response = await ticketApi.getTickets(Number(userId), status ? [status] : undefined);
      }

      if (!response || response.length === 0) {
        setTickets([]);
        setTotalTickets(0);
      } else {
        setTickets(response);
        setTotalTickets(response.length);
      }
    } catch (error) {
      alert('티켓 정보 조회 중 문제가 발생했습니다.');
      setTickets([]);
      setTotalTickets(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newStatus = event.target.value === 'ALL' ? undefined : event.target.value;
    setSelectedStatus(newStatus);
    fetchTickets(newStatus);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortToggle = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const formatDate = (date: any) => {
    if (!date) return ''; // null 또는 undefined일 경우 빈 문자열 반환

    // 문자열일 경우 dayjs로 파싱 후 변환
    if (typeof date === 'string') {
      return dayjs.utc(date).tz('Asia/Seoul').format('YYYY/MM/DD HH:mm:ss');
    }

    // 배열 형식의 날짜를 처리
    if (Array.isArray(date)) {
      const [year, month, day, hour, minute, second] = date.slice(0, 6);
      return dayjs
      .utc(new Date(year, month - 1, day, hour, minute, second))
      .tz('Asia/Seoul')
      .format('YYYY/MM/DD HH:mm:ss');
    }

    return ''; // 유효하지 않은 날짜 형식일 경우 빈 문자열 반환
  };

  const renderDate = (date: any) => {
    return formatDate(date);
  };

  const handleOpenUpdateModal = (ticket: TicketDTO) => {
    setSelectedTicket(ticket);
    setIsUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
    setSelectedTicket(null);
  };

  const handleUpdateSuccess = () => {
    fetchTickets();
    handleCloseUpdateModal();
  };

  const handleOpenDeleteModal = (ticket: TicketDTO) => {
    setSelectedTicket(ticket);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedTicket(null);
  };

  const handleDeleteTicket = async () => {
    if (selectedTicket) {
      try {
        await ticketApi.deleteTickets({
          userId: selectedTicket.userId,
          orderId: selectedTicket.orderId,
          initial: true,
          ticketId: selectedTicket.ticketId,
          ticketStatus: selectedTicket.status,
          status: [selectedTicket.status],
        });
        alert('티켓이 성공적으로 삭제되었습니다.');
        fetchTickets();
        handleCloseDeleteModal();
      } catch (error) {
        alert('티켓 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const statusLabels: Record<string, string> = {
    AVAILABLE: '사용가능',
    USED: '사용완료',
    UPDATED: '갱신됨',
    REFUNDED: '환불',
    CANCELED: '취소',
    EXPIRED: '만료',
  };

  const handleOpenBulkUpdateModal = () => {
    setIsBulkUpdateModalOpen(true);
  };

  const handleCloseBulkUpdateModal = () => {
    setIsBulkUpdateModalOpen(false);
    setBulkStatus('AVAILABLE');
    setBulkUsedAt(null);
  };

  const handleBulkUpdate = async () => {
    try {
      const usedAtFormatted = bulkUsedAt ? bulkUsedAt.toISOString() : null;
      await ticketApi.updateTicket(selectedTickets, bulkStatus, usedAtFormatted);

      alert('선택한 이용권이 수정되었습니다.');
      fetchTickets();
      setSelectedTickets([])
      handleCloseBulkUpdateModal();
    } catch (error) {
      alert('일괄 업데이트 중 문제가 발생했습니다.');
    }
  };

  // 체크박스 핸들러
  const handleSelectTicket = (ticketId: string) => {
    if (selectedTickets.includes(ticketId)) {
      setSelectedTickets(selectedTickets.filter((id) => id !== ticketId));
    } else {
      setSelectedTickets([...selectedTickets, ticketId]);
    }
  };

  const handleSelectAllTickets = () => {
    if (selectedTickets.length === currentPageTickets.length) {
      setSelectedTickets([]); // 모두 해제
    } else {
      setSelectedTickets(currentPageTickets.map((ticket) => ticket.ticketId)); // 현재 페이지 전체 선택
    }
  };

  return (
      <>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={2}>
          <Button
              variant="contained"
              color="primary"
              onClick={handleOpenBulkUpdateModal}
              style={{ marginRight: '1rem', marginTop: '1rem' }}
              disabled={selectedTickets.length === 0} // 티켓이 선택되지 않았을 경우 버튼 비활성화
          >
            일괄 수정
          </Button>
          <FormControl variant="outlined" size="small" style={{ marginRight: '1rem', marginTop: '1rem' }}>
            <InputLabel id="status-label">티켓 상태</InputLabel>
            <Select
                labelId="status-label"
                value={selectedStatus || 'ALL'}
                onChange={handleStatusChange}
                label="이용권 상태"
                style={{ minWidth: '120px' }}
            >
              <MenuItem value="ALL">전체</MenuItem>
              <MenuItem value="AVAILABLE">사용가능</MenuItem>
              <MenuItem value="USED">사용완료</MenuItem>
              <MenuItem value="UPDATED">갱신됨</MenuItem>
              <MenuItem value="REFUNDED">환불</MenuItem>
              <MenuItem value="CANCELED">취소</MenuItem>
              <MenuItem value="EXPIRED">만료</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={selectedTickets.length > 0 && selectedTickets.length < currentPageTickets.length}
                        checked={selectedTickets.length === currentPageTickets.length}
                        onChange={handleSelectAllTickets}
                    />
                  </TableCell>
                  <TableCell>티켓 ID</TableCell>
                  <TableCell>
                    생성일
                    <IconButton onClick={() => handleSortToggle('createdAt')} size="small">
                      {sortColumn === 'createdAt' && sortOrder === 'asc' ? (
                          <ArrowUpward fontSize="small" />
                      ) : (
                          <ArrowDownward fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    만료일
                    <IconButton onClick={() => handleSortToggle('expiredAt')} size="small">
                      {sortColumn === 'expiredAt' && sortOrder === 'asc' ? (
                          <ArrowUpward fontSize="small" />
                      ) : (
                          <ArrowDownward fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>상품종류</TableCell>
                  <TableCell>사용일</TableCell>
                  <TableCell>상태</TableCell>
                  <TableCell>이벤트명</TableCell>
                  <TableCell>관리</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        티켓 목록을 불러오는 중입니다...
                      </TableCell>
                    </TableRow>
                ) : currentPageTickets.length > 0 ? (
                    currentPageTickets.map((ticket) => (
                        <TableRow key={ticket.ticketId}>
                          <TableCell padding="checkbox">
                            <Checkbox
                                checked={selectedTickets.includes(ticket.ticketId)}
                                onChange={() => handleSelectTicket(ticket.ticketId)}
                            />
                          </TableCell>
                          <TableCell>{ticket.ticketId}</TableCell>
                          <TableCell>{renderDate(ticket.createdAt)}</TableCell>
                          <TableCell>{ticket.expiredAt ? renderDate(ticket.expiredAt) : 'N/A'}</TableCell>
                          <TableCell>{ticket.type}</TableCell>
                          <TableCell>{renderDate(ticket.usedAt)}</TableCell>
                          <TableCell>{statusLabels[ticket.status]}</TableCell>
                          <TableCell>{ticket.eventName || 'N/A'}</TableCell>
                          <TableCell>
                            <Button variant="outlined" size="small" onClick={() => handleOpenUpdateModal(ticket)}>
                              수정
                            </Button>
                            <Button variant="outlined" size="small" color="error" onClick={() => handleOpenDeleteModal(ticket)}>
                              삭제
                            </Button>
                          </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        검색된 티켓이 없습니다.
                      </TableCell>
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tickets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* 티켓 업데이트 모달 */}
        {isUpdateModalOpen && selectedTicket && (
            <Dialog open={isUpdateModalOpen} onClose={handleCloseUpdateModal} fullWidth maxWidth="sm">
              <UpdateTicketModal ticket={selectedTicket} onClose={handleCloseUpdateModal} onUpdateSuccess={handleUpdateSuccess} />
            </Dialog>
        )}

        {/* 티켓 삭제 모달 */}
        <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
          <DialogTitle>티켓 삭제</DialogTitle>
          <DialogContent>정말로 티켓을 삭제하시겠습니까?</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} color="secondary">
              취소
            </Button>
            <Button onClick={handleDeleteTicket} color="primary">
              삭제
            </Button>
          </DialogActions>
        </Dialog>

        {/* 일괄 업데이트 모달 */}
        <Dialog open={isBulkUpdateModalOpen} onClose={handleCloseBulkUpdateModal} fullWidth maxWidth="sm">
          <DialogTitle>일괄 수정</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel>상태</InputLabel>
              <Select value={bulkStatus} onChange={(e) => setBulkStatus(e.target.value)} fullWidth>
                <MenuItem value="AVAILABLE">사용가능</MenuItem>
                <MenuItem value="USED">사용완료</MenuItem>
                <MenuItem value="UPDATED">갱신됨</MenuItem>
                <MenuItem value="REFUNDED">환불</MenuItem>
                <MenuItem value="CANCELED">취소</MenuItem>
                <MenuItem value="EXPIRED">만료</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                  label="사용 일자"
                  value={bulkUsedAt}
                  onChange={(date) => setBulkUsedAt(date)}
                  format="YYYY/MM/DD HH:mm:ss"
                  slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseBulkUpdateModal} color="secondary">
              취소
            </Button>
            <Button onClick={handleBulkUpdate} color="primary">
              수정
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default TicketPage;
