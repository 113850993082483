import Api from "../configs/apiConfigs";
import {RequestTicketDTO, TicketDTO} from "../model/TicketModel";
import {AxiosRequestConfig} from "axios";

export default class TicketApi extends Api {
  async createTickets(data: RequestTicketDTO): Promise<string[]> {
    try {
      const response = await super.post<string[]>(
          `${process.env.REACT_APP_API_URL}/payment/admin/tickets`,
          JSON.stringify(data)
      );
      return response.data;
    } catch (error) {
      throw new Error('failed to create tickets')
    }
  }

// 회원이 가지고 있는 이용권 조회
  async getTickets(userId: number, status?: string[]): Promise<TicketDTO[]> {
    try {
      // status가 있는 경우, URL에 쿼리 파라미터로 추가
      const statusParams = status ? `&status=${status.join(",")}` : "";
      const response = await super.get<TicketDTO[]>(
          `${process.env.REACT_APP_API_URL}/payment/admin/tickets?userId=${userId}${statusParams}`
      );
      return response.data;
    } catch (error) {
      throw new Error('failed to get tickets');
    }
  }

  // 주문 ID와 상태로 티켓을 조회, 주문에 포함된 이용권 조회기능
  async getTicketsByOrderId(orderId: string, status?: string[]): Promise<TicketDTO[]> {
    try {
      // 상태 필터가 있는 경우 쿼리 파라미터로 추가
      const statusParams = status ? `?status=${status.join(",")}` : "";
      const response = await super.get<TicketDTO[]>(
          `${process.env.REACT_APP_API_URL}/payment/admin/orders/${orderId}/tickets${statusParams}`
      );
      return response.data;
    } catch (error) {
      throw new Error('failed to get tickets by order ID');
    }
  }

  //티켓 업데이트 매서드, 사용일자를 추가하거나 없앨 수 있음
  async updateTicket(ticketIds: string[], status: string, usedAt: string | null): Promise<void> {
    try {
      const params = new URLSearchParams({
        ticketIds: ticketIds.join(','), // ticketIds를 문자열로 변환
        status,
      });

      // usedAt이 존재하는 경우에만 쿼리 파라미터에 추가
      if (usedAt) {
        params.append('usedAt', usedAt);
      }

      const response = await super.put<void>(
          `${process.env.REACT_APP_API_URL}/payment/admin/ticket?${params.toString()}`,
          {} // 본문(body)로 빈 객체 전달
      );

      return response.data;
    } catch (error) {
      throw new Error('failed to update ticket');
    }
  }

  // 티켓 삭제
  async deleteTickets(requestTicketDTO: RequestTicketDTO): Promise<void> {
    try {
      const config: AxiosRequestConfig = {
        data: requestTicketDTO // delete 요청에서 data를 전달하기 위해 Axios의 config 객체에 data 필드 사용
      };

      await super.delete(
          `${process.env.REACT_APP_API_URL}/payment/admin/tickets`,
          config
      );
    } catch (error) {
      throw new Error('failed to delete tickets');
    }
  }
}