import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  IconButton,
  Collapse,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import PremiumRequestLogApi from '../../modules/premiumRequestLog'
import {
  CustomInput,
  PremiumLogResponseModel,
  EngineVersions,
} from '../../model/PremiumRequestLog/PremiumLogResponseModel'
import { PageModel } from '../../model/common/PageModel'
import { PremiumLogRequestParamModel } from '../../model/PremiumRequestLog/PremiumLogRequestParamModel'
import BuildStatus from '../../model/BuildStatus.enum'
import { Link } from 'react-router-dom'

const PremiumRequestLogListPage = () => {
  const [logs, setLogs] = useState<PremiumLogResponseModel[]>([])
  const [selectedLog, setSelectedLog] =
    useState<PremiumLogResponseModel | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [userId, setUserId] = useState<string>('')
  const [buildName, setBuildName] = useState<string>('')
  const [buildStatus, setBuildStatus] = useState<string>('')
  const [isCustomInput, setIsCustomInput] = useState<string>('')
  const [reqPnu, setReqPnu] = useState<string>('')
  const [inputPage, setInputPage] = useState<string>('')
  const [isCustomInputOpen, setIsCustomInputOpen] = useState<boolean>(false)
  const [isEngineVersionsOpen, setIsEngineVersionsOpen] =
    useState<boolean>(false)

  useEffect(() => {
    fetchLogs()
  }, [page])

  const fetchLogs = async () => {
    try {
      const queryParams: PremiumLogRequestParamModel = {
        page,
        size: 20,
      }

      if (userId) queryParams.userId = parseInt(userId)
      if (buildName) queryParams.buildName = buildName
      if (buildStatus && buildStatus !== '선택 없음')
        queryParams.buildStatus = buildStatus
      if (reqPnu) queryParams.reqPnu = reqPnu
      if (isCustomInput === '있음') queryParams.isCustomInput = true
      if (isCustomInput === '없음') queryParams.isCustomInput = false

      const logList: PageModel<PremiumLogResponseModel> =
        await new PremiumRequestLogApi().getPremiumRequestLogs(queryParams)

      setLogs(logList.content)
      setTotalPages(logList.totalPages)
      setTotalElements(logList.totalElements)
    } catch (err) {
      console.error('로그 데이터를 가져오는 중 오류가 발생했습니다.', err)
    }
  }

  const handleRowClick = (log: PremiumLogResponseModel) => {
    setSelectedLog(log)
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setSelectedLog(null)
  }

  const formatDate = (dateArray: number[]) => {
    const [year, month, day, hour, minute, second] = dateArray
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }

  const renderCustomInput = (customInput: CustomInput | undefined) => {
    if (!customInput) {
      return (
        <Box border={1} borderRadius={3} p={2} mt={2} textAlign="center">
          <Typography variant="body2">법규 인풋 정보 없음</Typography>
        </Box>
      )
    }
    return (
      <Box border={1} borderRadius={3} p={2} mt={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" gutterBottom>
            법규 입력 정보
          </Typography>
          <IconButton
            size="small"
            onClick={() => setIsCustomInputOpen(!isCustomInputOpen)}
          >
            {isCustomInputOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={isCustomInputOpen}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Hash Key</TableCell>
                <TableCell>{customInput.hashKey}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>건폐율</TableCell>
                <TableCell>{customInput.maxBcr ?? '없음'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>용적률</TableCell>
                <TableCell>{customInput.maxFar ?? '없음'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>최고 층수</TableCell>
                <TableCell>{customInput.maxFloor ?? '없음'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>최고 높이</TableCell>
                <TableCell>{customInput.maxHeight ?? '없음'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </Box>
    )
  }

  const renderEngineVersions = (engineVersions: EngineVersions) => {
    return (
      <Box border={1} borderRadius={3} p={2} mt={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" gutterBottom>
            엔진 버전 정보
          </Typography>
          <IconButton
            size="small"
            onClick={() => setIsEngineVersionsOpen(!isEngineVersionsOpen)}
          >
            {isEngineVersionsOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={isEngineVersionsOpen}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>DRL 버전</TableCell>
                <TableCell>{engineVersions.drlVersion ?? '없음'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DRL 멀티 버전</TableCell>
                <TableCell>
                  {engineVersions.drlMultiVersion ?? '없음'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DRL 리테일 버전</TableCell>
                <TableCell>
                  {engineVersions.drlRetailVersion ?? '없음'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GA 버전</TableCell>
                <TableCell>{engineVersions.gaVersion ?? '없음'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GA 멀티 버전</TableCell>
                <TableCell>{engineVersions.gaMultiVersion ?? '없음'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GA 리테일 버전</TableCell>
                <TableCell>
                  {engineVersions.gaRetailVersion ?? '없음'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>GA 소유자 버전</TableCell>
                <TableCell>{engineVersions.gaOwnerVersion ?? '없음'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </Box>
    )
  }

  const handleSearch = () => {
    setPage(1)
    fetchLogs()
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handleInputPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputPage(event.target.value)
  }

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPage)
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= totalPages) {
      setPage(pageNumber)
    }
  }

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const renderPaginationButtons = () => {
    const buttons = []
    const startPage = Math.floor((page - 1) / 10) * 10 + 1
    const endPage = Math.min(startPage + 9, totalPages)

    if (startPage > 1) {
      buttons.push(
        <Button key="prev" onClick={() => handlePageChange(startPage - 10)}>
          이전
        </Button>
      )
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          variant={page === i ? 'contained' : 'text'}
        >
          {i}
        </Button>
      )
    }

    if (endPage < totalPages) {
      buttons.push(
        <Button key="next" onClick={() => handlePageChange(startPage + 10)}>
          다음
        </Button>
      )
    }

    return buttons
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        프리미엄 분석 로그 목록
      </Typography>
      <Box display="flex" justifyContent="flex-end" mb={2} gap={1}>
        <TextField
          label="사용자 ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          onKeyPress={handleEnterKeyPress}
          variant="outlined"
          size="small"
        />
        <TextField
          label="빌드 이름"
          value={buildName}
          onChange={(e) => setBuildName(e.target.value)}
          onKeyPress={handleEnterKeyPress}
          variant="outlined"
          size="small"
        />
        <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
          <InputLabel>빌드 상태</InputLabel>
          <Select
            value={buildStatus}
            onChange={(e) => setBuildStatus(e.target.value as string)}
            label="빌드 상태"
          >
            <MenuItem value="">선택 없음</MenuItem>
            {Object.values(BuildStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="대표 분석 필지"
          value={reqPnu}
          onChange={(e) => setReqPnu(e.target.value)}
          onKeyPress={handleEnterKeyPress}
          variant="outlined"
          size="small"
        />
        <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
          <InputLabel>법규 인풋 여부</InputLabel>
          <Select
            value={isCustomInput}
            onChange={(e) => setIsCustomInput(e.target.value as string)}
            label="법규 인풋 여부"
          >
            <MenuItem value="">선택 없음</MenuItem>
            <MenuItem value="있음">있음</MenuItem>
            <MenuItem value="없음">없음</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          검색
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>필지</TableCell>
              <TableCell>빌드 상태</TableCell>
              <TableCell>빌드 이름</TableCell>
              <TableCell>사용자 ID</TableCell>
              <TableCell>Log ID</TableCell>
              <TableCell>생성 일자</TableCell>
              <TableCell>수정 일자</TableCell>
              <TableCell>법규 인풋 여부</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log) => (
              <TableRow
                key={log.logId}
                onClick={() => handleRowClick(log)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{log.pnu}</TableCell>
                <TableCell>{log.buildStatus}</TableCell>
                <TableCell>{log.buildName}</TableCell>
                <TableCell>{log.userId}</TableCell>
                <TableCell>{log.logId}</TableCell>
                <TableCell>{formatDate(log.createdAt)}</TableCell>
                <TableCell>{formatDate(log.updatedAt)}</TableCell>
                <TableCell>{log.customInput ? '있음' : '없음'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Typography>
          총 {totalElements}개의 로그, {totalPages} 페이지
        </Typography>
        <Box>{renderPaginationButtons()}</Box>
        <Box display="flex" alignItems="center" gap={1}>
          <TextField
            label="페이지"
            value={inputPage}
            onChange={handleInputPageChange}
            onKeyPress={handleEnterKeyPress}
            variant="outlined"
            size="small"
            style={{ width: '80px' }}
          />
          <Button variant="contained" color="primary" onClick={handleGoToPage}>
            이동
          </Button>
        </Box>
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>로그 상세 조회</DialogTitle>
        <DialogContent>
          {selectedLog && (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>PNU</strong>
                  </TableCell>
                  <TableCell>{selectedLog.pnu}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>빌드 상태</strong>
                  </TableCell>
                  <TableCell>{selectedLog.buildStatus}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>빌드 이름</strong>
                  </TableCell>
                  <TableCell>{selectedLog.buildName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>사용자 ID</strong>
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/details/${selectedLog.userId}`}>
                      {selectedLog.userId}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Log ID</strong>
                  </TableCell>
                  <TableCell>{selectedLog.logId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>생성 일자</strong>
                  </TableCell>
                  <TableCell>{formatDate(selectedLog.createdAt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>수정 일자</strong>
                  </TableCell>
                  <TableCell>{formatDate(selectedLog.updatedAt)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {selectedLog && renderCustomInput(selectedLog.customInput)}
          {selectedLog && renderEngineVersions(selectedLog.engineVersions)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PremiumRequestLogListPage
