import { Outlet } from 'react-router-dom'

const RequestLogPageLayout = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default RequestLogPageLayout
