import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  Button,
  Box,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import UsersApi from '../../modules/users';
import { MembershipInfoModel } from '../../model/UserModel';

const MembershipUpdatePage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const api = new UsersApi();

  const [formData, setFormData] = useState<MembershipInfoModel>({
    userId: 0,
    membershipType: '',
    membershipStartedAt: [],
    membershipExpiredAt: [],
    membershipTrialState: '',
    membershipTrialDate: [],
    membershipMethod: '',
    isFirstMembershipDiscount: false,
    discountUsageCount: 0,
    openChatKakaoId: 0,
    renderCount: 0,
    isMembership: false,
    isCompany: false,
  });

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [trialEndDate, setTrialEndDate] = useState<Dayjs | null>(null);

  const fetchUsersInfo = async (id: string) => {
    const membershipInfo = await api.getUserMembershipInfo(userId!!);
    if (membershipInfo) {
      setFormData(membershipInfo);

      setStartDate(
          membershipInfo.membershipStartedAt && membershipInfo.membershipStartedAt.length > 0
              ? dayjs(
                  new Date(
                      membershipInfo.membershipStartedAt[0],
                      membershipInfo.membershipStartedAt[1] - 1,
                      membershipInfo.membershipStartedAt[2],
                      membershipInfo.membershipStartedAt[3],
                      membershipInfo.membershipStartedAt[4],
                      membershipInfo.membershipStartedAt[5] || 1
                  )
              )
              : dayjs()
      );

      setEndDate(
          membershipInfo.membershipExpiredAt && membershipInfo.membershipExpiredAt.length > 0
              ? dayjs(
                  new Date(
                      membershipInfo.membershipExpiredAt[0],
                      membershipInfo.membershipExpiredAt[1] - 1,
                      membershipInfo.membershipExpiredAt[2],
                      membershipInfo.membershipExpiredAt[3],
                      membershipInfo.membershipExpiredAt[4],
                      membershipInfo.membershipExpiredAt[5] || 59
                  )
              )
              : dayjs()
      );

      setTrialEndDate(
          membershipInfo.membershipTrialDate && membershipInfo.membershipTrialDate.length > 0
              ? dayjs(
                  new Date(
                      membershipInfo.membershipTrialDate[0],
                      membershipInfo.membershipTrialDate[1] - 1,
                      membershipInfo.membershipTrialDate[2],
                      membershipInfo.membershipTrialDate[3],
                      membershipInfo.membershipTrialDate[4],
                      membershipInfo.membershipTrialDate[5] || 59
                  )
              )
              : null
      );
    }
  };


  useEffect(() => {
    fetchUsersInfo(userId!!);
  }, [userId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value || '',
    }));
  };

  const handleDateChange = (field: string, date: Dayjs | null) => {
    if (date) {
      const dateArray = [
        date.year(),
        date.month() + 1,  // Month is 0-indexed, so we add 1
        date.date(),
        date.hour(),        // 시간 정보 포함
        date.minute(),      // 분 정보 포함
        date.second(),      // 초 정보 포함
      ];

      setFormData({
        ...formData,
        [field]: dateArray,
      });
    }
  };


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      // API 호출
      const response = await api.updateUserMembership(userId!!, formData);

      // 응답이 AxiosResponse 타입인지 확인
      if ('status' in response) {
        if (response.status === 200) {
          alert('수정되었습니다.');
          window.location.reload();
        } else {
          throw new Error('잘못된 응답입니다. 서버에 문제가 있습니다.');
        }
      } else {
        // 만약 응답이 status를 포함하지 않으면, 예외 발생
        throw new Error('서버에서 예상치 못한 응답을 받았습니다.');
      }
    } catch (error: any) {
      alert(error.response?.data?.msg || '수정 중 오류가 발생했습니다. 입력값을 확인해주세요');
    }
  };


  return (
      <Container maxWidth="sm">
        <Box mb={3}>
          <Typography variant="h5" gutterBottom>
            멤버십 수정
          </Typography>

          <FormGroup sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>멤버십 기간</InputLabel>
              <Select
                  label="기간 선택"
                  name="membershipType"
                  value={formData.membershipType}
                  onChange={handleSelectChange}
                  required
              >
                <MenuItem value="MONTH">1개월</MenuItem>
                <MenuItem value="SIX_MONTH">6개월</MenuItem>
                <MenuItem value="YEAR">12개월</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                  label="시작일"
                  value={startDate}
                  onChange={(date) => {
                    // 기본값 적용: 사용자가 시간을 선택하지 않으면 00:00:01
                    const startTime = date ? (startDate ? date : date.set('hour', 0).set('minute', 0).set('second', 1)) : null;
                    setStartDate(startTime);
                    handleDateChange('membershipStartedAt', startTime);
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                  label="만료일"
                  value={endDate}
                  onChange={(date) => {
                    // 기본값 적용: 사용자가 시간을 선택하지 않으면 23:59:59
                    const endTime = date ? (endDate ? date : date.set('hour', 23).set('minute', 59).set('second', 59)) : null;
                    setEndDate(endTime);
                    handleDateChange('membershipExpiredAt', endTime);
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>무료체험 상태</InputLabel>
              <Select
                  label="무료체험 상태"
                  name="membershipTrialState"
                  value={formData.membershipTrialState}
                  onChange={handleSelectChange}
                  required
              >
                <MenuItem value="AVAILABLE">이용가능</MenuItem>
                <MenuItem value="USE">사용완료</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                  label="무료체험 만료일"
                  value={trialEndDate}
                  onChange={(date) => {
                    // 기본값 적용: 사용자가 시간을 선택하지 않으면 23:59:59
                    const trialEndTime = date ? (endDate ? date : date.set('hour', 23).set('minute', 59).set('second', 59)) : null;
                    setTrialEndDate(trialEndTime);
                    handleDateChange('membershipTrialDate', trialEndTime);
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>결제수단</InputLabel>
              <Select
                  label="결제수단 선택"
                  name="membershipMethod"
                  value={formData.membershipMethod}
                  onChange={handleSelectChange}
                  required
              >
                <MenuItem value="IMP">아임포트</MenuItem>
                <MenuItem value="INAPP">인앱</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <TextField
                label="AI생성이미지 횟수"
                variant="outlined"
                name="renderCount"
                value={formData.renderCount}
                onChange={handleChange}
                fullWidth
            />
          </FormGroup>

          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              수정
            </Button>
          </Box>
        </Box>
      </Container>
  );
};

export default MembershipUpdatePage;
