import Api from '../configs/apiConfigs'
import { PageModel } from '../model/common/PageModel'
import { LightLogRequestParamModel } from '../model/LightRequestLog/LightLogRequestParamModel'
import { LightLogResponseModel } from '../model/LightRequestLog/LightLogResponseModel'

export default class LightRequestLogApi extends Api {
  async getLightRequestLogs(
    params: LightLogRequestParamModel
  ): Promise<PageModel<LightLogResponseModel>> {
    try {
      const response = await super.get<PageModel<LightLogResponseModel>>(
        `${process.env.REACT_APP_API_URL}/admin/light/log`,
        { params }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
}
