import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import OrdersApi from '../../modules/order';
import PaymentApi from '../../modules/payment';
import {OrderSummaryDTO, PaymentDTO, CreateAdminPaymentReq} from '../../model/OrderModel';
import PaymentForm from "./PaymentForm";
import TicketApi from "../../modules/ticket";
import TicketPage from "./TicketsPage";

const OrderDetailPage: React.FC = () => {
  const { orderId, userId: userIdString, status } = useParams<{
    orderId: string;
    userId: string;
    status: string;
  }>();
  const navigate = useNavigate(); // useNavigate 훅 추가

  // useParams에서 undefined 처리
  if (!orderId || !status) {
    throw new Error("필수 입력 정보가 누락되었습니다.");
  }

// userId를 number로 변환하며, NaN 처리
  const userId = Number(userIdString);

  const [orderData, setOrderData] = useState<OrderSummaryDTO | null>(null);
  const [paymentData, setPaymentData] = useState<PaymentDTO | null>(null);
  const [loading, setLoading] = useState(true);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [paymentFormData, setPaymentFormData] = useState<CreateAdminPaymentReq>({
    type: 'SWK',
    method: 'CARD',
    amount: 0,
    paidStatus: 'PAID',
  });
  const [refreshData, setRefreshData] = useState(false);

  const paymentMethods = ['CARD', 'VBANK', 'MOBILE', 'TRANS', 'POINT', 'INAPP', 'COUPON', 'UNKNOWN'];
  const paymentStatuses = ['READY', 'PAID', 'FAILED', 'CANCELLED', 'REFUND_REQUIRED', 'UNKNOWN', 'PARTIAL_CANCELLED'];
  const vans = ['IMP', 'APPLE', 'GOOGLE', 'SWK', 'UNKNOWN'];

  // 영어 -> 한글 매핑
  const paymentMethodLabels: Record<string, string> = {
    CARD: '카드',
    VBANK: '가상계좌',
    MOBILE: '모바일',
    TRANS: '계좌이체',
    POINT: '포인트',
    INAPP: '인앱',
    COUPON: '쿠폰',
    UNKNOWN: '알 수 없음',
  };

  const paymentStatusLabels: Record<string, string> = {
    READY: '준비중',
    PAID: '결제 완료',
    FAILED: '결제 실패',
    CANCELLED: '결제 취소',
    REFUND_REQUIRED: '환불 요청',
    UNKNOWN: '알 수 없음',
    PARTIAL_CANCELLED: '부분 취소',
  };

  const vanLabels: Record<string, string> = {
    IMP: '아임포트',
    APPLE: '애플',
    GOOGLE: '구글',
    SWK: 'SWK',
    UNKNOWN: '알 수 없음',
  };



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const ordersApi = new OrdersApi();
        const paymentApi = new PaymentApi();

        const order = await ordersApi.getOrderByIdAndStatus(orderId, status);
        const payment = await paymentApi.getPayment(orderId, status);

        setOrderData(order);
        setPaymentData(payment);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [orderId, userId, status, refreshData]);

  const formatDate = (date: any) => {
    if (Array.isArray(date)) {
      const [year, month, day, hour, minute, second] = date.slice(0, 6);
      return new Date(year, month - 1, day, hour, minute, second).toLocaleString(); // ISO String으로 변환
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleString(); // ISO 문자열을 로컬 날짜 형식으로 변환
    }
    return date;
  };

  const renderDate = (date: any) => {
    return formatDate(date);
  };

  const handleOpenPaymentDialog = () => {
    if (paymentData) {
      setPaymentFormData({
        type: paymentData.van || 'SWK',
        method: paymentData.method || 'CARD',
        amount: paymentData.amount || orderData?.orderedPrice || 0, // 기본값 설정
        paidStatus: paymentData.paidStatus || 'PAID',
        vbankCode: paymentData.vbankCode || '',
        vbankName: paymentData.vbankName || '',
        vbankNum: paymentData.vbankNum || '',
        vbankHolder: paymentData.vbankHolder || '',
      });
    } else {
      setPaymentFormData({
        type: 'SWK',
        method: 'CARD',
        amount: orderData?.orderedPrice || 0, // 기본값 설정
        paidStatus: 'PAID',
      });
    }
    setIsPaymentDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsPaymentDialogOpen(false);
    window.location.reload();

  };

  const handleCreateOrUpdatePayment = async () => {
    const paymentApi = new PaymentApi();

    // orderData에서 userId를 추출
    const extractedUserId = orderData?.userId;

    if (!extractedUserId || isNaN(extractedUserId)) {
      alert("Invalid user ID. Please check the user ID.");
      return;
    }

    try {
      if (paymentData) {
        // 결제 정보가 있으면 업데이트
        await paymentApi.updatePayment(extractedUserId, orderId, paymentFormData);
      } else {
        // 결제 정보가 없으면 새로 생성
        await paymentApi.createPayment(extractedUserId, orderId, paymentFormData);
        navigate(`/orders/detail/${orderId}/${extractedUserId}/COMPLETED`, {replace: true});
      }

      alert('결제가 성공적으로 처리되었습니다.');
      setIsPaymentDialogOpen(false);
      const updatedPayment = await paymentApi.getPayment(orderId, 'COMPLETED');
      setPaymentData(updatedPayment);
      setRefreshData(true); // 데이터 갱신을 요청


    } catch (error) {
      alert("Failed to process payment");
    }
  };


  if (loading) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress/>
        </Box>
    );
  }

  return (
      <Box p={3}>
        {/* User Info */}
        <Paper elevation={3} style={{padding: '16px', marginBottom: '16px'}}>
          <Typography variant="h6" gutterBottom>
            회원 정보
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography><strong>회원 ID:</strong> {orderData?.user?.userId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>이름:</strong> {orderData?.user?.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>E-mail:</strong> {orderData?.user?.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>휴대폰 번호:</strong> {orderData?.user?.phoneNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>가입일:</strong> {renderDate(orderData?.user?.signUpDate) || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>최근
                방문일:</strong> {renderDate(orderData?.user?.recentVisitDate) || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>멤버십
                시작일:</strong> {renderDate(orderData?.user?.membershipStartedAt) || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>멤버십
                종료일:</strong> {renderDate(orderData?.user?.membershipExpiredAt) || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* Order Info */}
        <Paper elevation={3} style={{padding: '16px', marginBottom: '16px'}}>
          <Typography variant="h6" gutterBottom>
            주문 정보
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography><strong>주문 ID:</strong> {orderData?.orderId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>회원 ID:</strong> {orderData?.userId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>주문금액:</strong> {orderData?.orderedPrice.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>할인:</strong> {orderData?.discount.toLocaleString()}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>상품가격:</strong> {orderData?.price.toLocaleString()}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>주문 일시:</strong> {renderDate(orderData?.orderedAt)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>이용권 수:</strong> {orderData?.ticket?.count || 0}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>쿠폰 타입:</strong> {orderData?.coupon?.couponType || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>이벤트명:</strong> {orderData?.event?.name || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>구매 상품:</strong> {orderData?.item?.name || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>상태:</strong> {orderData?.status}</Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* Payment Info */}
        <Paper elevation={3} style={{padding: '16px', marginBottom: '16px'}}>
          <Typography variant="h6" gutterBottom>
            결제 정보
          </Typography>
          {paymentData ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>결제 ID:</strong> {paymentData.paymentId}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>결제수단:</strong> {paymentData.method}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>결제일:</strong> {renderDate(paymentData.paidAt)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>상태:</strong> {paymentStatusLabels[paymentData.paidStatus]}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>결제액:</strong> {paymentData.amount?.toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>결제대행:</strong> {paymentData.van}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>가상계좌 은행 코드:</strong> {paymentData.vbankCode || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>가상계좌 은행 이름:</strong> {paymentData.vbankName || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>가상계좌 계좌번호:</strong> {paymentData.vbankNum || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography><strong>가상계좌 예금주:</strong> {paymentData.vbankHolder || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" color="primary" onClick={handleOpenPaymentDialog}>
                    결제정보 수정
                  </Button>
                </Grid>
              </Grid>
          ) : (
              <Button variant="outlined" color="primary" onClick={handleOpenPaymentDialog}>
                결제정보 생성
              </Button>
          )}
        </Paper>

        {/* 이용권 정보 */}
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
          <Typography variant="h6" gutterBottom>
            이용권 정보
          </Typography>
          {/* orderId를 TicketPage로 props로 넘겨줍니다 */}
          <TicketPage orderId={orderId}/>
        </Paper>


        {/* 결제 정보 생성/수정 모달 */}
        <Dialog open={isPaymentDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>{paymentData ? '결제정보 수정' : '결제정보 생성'}</DialogTitle>
          <DialogContent>
            <PaymentForm
                formData={paymentFormData}
                setFormData={setPaymentFormData}
                orderId={orderId}
                orderData={orderData}
                userId={userId}
                isEditMode={!!paymentData}
                onClose={handleCloseDialog}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
};

export default OrderDetailPage;
