import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import UsersApi from '../../modules/users';
import { DeactivationStatisticsDTO } from '../../model/UserModel';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

const DeactivationStatisticsPage: React.FC = () => {
  const [statistics, setStatistics] = useState<DeactivationStatisticsDTO | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태
  const [modalData, setModalData] = useState<Array<{ name: string; value: number }>>([]);

  useEffect(() => {
    fetchDeactivationStatistics();
  }, []);

  const fetchDeactivationStatistics = async () => {
    try {
      const usersApi = new UsersApi();
      const response = await usersApi.getDeactivationStatistics();

      // 빈 문자열을 "기타"로 합치기
      const combinedData = combineEmptyStrings(response);

      setStatistics(combinedData);
    } catch (error) {
      throw error;
    }
  };

  const combineEmptyStrings = (data: DeactivationStatisticsDTO) => {
    const combineOther = (data: Record<string, number>) => {
      let otherCount = 0;
      const newData: Record<string, number> = {};

      Object.entries(data).forEach(([key, value]) => {
        if (key === '' || key === '기타') {
          otherCount += value;
        } else {
          newData[key] = value;
        }
      });

      if (otherCount > 0) {
        newData['기타(무응답포함)'] = otherCount;
      }

      return newData;
    };

    return {
      ...data,
      reasons: combineOther(data.reasons),
      jobDistribution: combineOther(data.jobDistribution),
      usePurposeDistribution: combineOther(data.usePurposeDistribution),
    };
  };

  const handleModalOpen = (data: Record<string, number>) => {
    const chartData = Object.entries(data)
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => b.value - a.value);
    setModalData(chartData); // 모달에 모든 데이터를 저장
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const renderPieChart = (data: Record<string, number>, title: string) => {
    // test 또는 테스트가 포함된 항목을 필터링
    const filteredData = Object.entries(data)
    .filter(([name]) => !name.toLowerCase().includes('test') && !name.includes('테스트')) // 필터링 조건 추가
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => b.value - a.value);

    const total = filteredData.reduce((acc, item) => acc + item.value, 0);
    // 데이터가 없거나 값이 모두 0인 경우 차트와 박스를 숨김
    if (total === 0) {
      return null;
    }
    const top5Data = filteredData.slice(0, 5); // 상위 5개만 범례에 표시
    const top3Data = filteredData.slice(0, 3); // 상위 3개는 그래프에 라벨 표시

    return (
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px', width: '90%' }}>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                  data={filteredData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  labelLine={false}  // 라벨 라인을 기본적으로 모두 숨김
                  label={({ name, value }) => {
                    const percent = (value / total) * 100;  // 퍼센트 계산
                    // 상위 5개의 항목에만 라벨을 표시, 대신 3퍼센트 이하일 경우에는 생략
                    if (top5Data.some((item) => item.name === name) && percent>=3) {
                      return `${name}: ${percent.toFixed(2)}%`;
                    }
                    return null;  // 나머지 항목은 라벨을 숨김 (라벨과 라벨 라인 모두 숨김)
                  }}
              >
                {filteredData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>

              <Tooltip formatter={(value: number) => `${value} (${((value / total) * 100).toFixed(2)}%)`} />
              <Legend
                  layout="vertical"
                  align="right"
                  verticalAlign="middle"
                  payload={top5Data.map((entry, index) => ({
                    id: entry.name,
                    type: 'square',
                    value: `${entry.name} (${((entry.value / total) * 100).toFixed(2)}%)`, // 범례에 퍼센트 표시
                    color: COLORS[index % COLORS.length],
                  }))}
              />
            </PieChart>
          </ResponsiveContainer>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button variant="contained" color="primary" onClick={() => handleModalOpen(data)}>
              자세히 보기
            </Button>
          </Box>
        </Paper>
    );
  };

  const renderModal = () => (
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper
            elevation={3}
            style={{ padding: '16px', margin: 'auto', marginTop: '50px', width: '80%', maxHeight: '80%', overflow: 'auto' }}
        >
          <Typography variant="h6" gutterBottom>
            전체 항목 보기
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            ⚠️ 유의사항: 테스트 목적으로 임의생성한 탈퇴 데이터(test, 테스트 등)는 필터링되어 표시되지 않습니다.
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>항목</TableCell>
                  <TableCell>개수</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalData
                .filter((entry) => !entry.name.toLowerCase().includes('test') && !entry.name.includes('테스트')) // 필터링 추가
                .map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.name}</TableCell>
                      <TableCell>{entry.value}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" color="secondary" onClick={handleModalClose}>
              닫기
            </Button>
          </Box>
        </Paper>
      </Modal>
  );

  return (
      <Box display="flex" flexWrap="wrap" justifyContent="space-around" sx={{ width: '95%' }}>
        <Box sx={{ width: '100%', p: 2 }}>
          {statistics ? (
              <>
                {renderPieChart(statistics.reasons, '탈퇴 이유')}
                {renderPieChart(statistics.jobDistribution, '직업 분포')}
                {renderPieChart(statistics.usePurposeDistribution, '가입(사용) 목적')}
                {renderPieChart({ '투자 경험 있음': statistics.hasEverInvestedCount, '투자 경험 없음': statistics.totalDeactivatedUsers - statistics.hasEverInvestedCount }, '투자 경험 유무')}
                {renderPieChart({ '경험 있음': statistics.hasEverDealCount, '경험 없음': statistics.totalDeactivatedUsers - statistics.hasEverDealCount }, '구매 경험 여부')}
                {renderModal()} {/* 모달을 렌더링 */}
              </>
          ) : (
              <Typography variant="h6">Loading...</Typography>
          )}
        </Box>
      </Box>
  );
};

export default DeactivationStatisticsPage;
