import React, {useEffect, useState} from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@mui/material';
import UsersApi from '../../modules/users';
import OrdersApi from '../../modules/order';
import PaymentApi from '../../modules/payment';
import {CustomerInfoDTO, UserPurchaseInfoDTO, MembershipInfoModel} from '../../model/UserModel';
import MembershipUpdatePage from './MembershipUpdatePage'; // 멤버쉽 업데이트 페이지 모달로 사용
import {useNavigate, useParams} from 'react-router-dom';
import TicketsPage from "../orders/TicketsPage";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const UserDetailsPage: React.FC = () => {
  const {userId} = useParams<{ userId: string }>();
  const [userInfo, setUserInfo] = useState<CustomerInfoDTO | null>(null);
  const [linkedAccounts, setLinkedAccounts] = useState<CustomerInfoDTO[]>([]); // 연동 계정 상태 추가
  const [purchaseInfo, setPurchaseInfo] = useState<UserPurchaseInfoDTO | null>(null);
  const [membershipInfo, setMembershipInfo] = useState<MembershipInfoModel | null>(null);
  const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false); // 멤버쉽 업데이트 모달 상태
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null); // 선택된 userId 상태
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false); // 모달 상태 관리
  const api = new UsersApi();
  const ordersApi = new OrdersApi();
  const paymentApi = new PaymentApi();

  const navigate = useNavigate();

  // 플러그인 설정
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // 서울 시간대 설정
  dayjs.tz.setDefault('Asia/Seoul');

  useEffect(() => {
    if (userId) {
      fetchUserInfo(parseInt(userId));
      fetchUserPurchaseInfo(parseInt(userId));
      fetchUserMembershipInfo(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (userInfo) {
      fetchLinkedAccounts();
    }
  }, [userInfo]); // userInfo가 변경될 때만 실행

  const fetchUserInfo = async (userId: number) => {
    try {
      const response = await api.getUserInfo(userId);
      setUserInfo(response);
    } catch (error) {
      setErrorMessage('user info 불러오기에 실패하였습니다.');
    }
  };

  const fetchUserPurchaseInfo = async (userId: number) => {
    try {
      const response = await api.getUserPurchaseInfo(userId);
      setPurchaseInfo(response);
    } catch (error) {
      setErrorMessage('구매 정보 불러오기에 실패하였습니다.');
    }
  };

  const fetchUserMembershipInfo = async (userId: string) => {
    try {
      const response = await api.getUserMembershipInfo(userId);
      setMembershipInfo(response ?? null);
    } catch (error) {
      console.error('멤버쉽 정보 불러오기에 실패하였습니다.', error);
      setMembershipInfo(null);
    }
  };

  const handleOpenMembershipModal = () => {
    setIsMembershipModalOpen(true);
  };

  const handleCloseMembershipModal = () => {
    setIsMembershipModalOpen(false);
  };

  const handleUserTicketsPage = (userId: number) => {
    setSelectedUserId(userId); // 선택된 userId 설정
    setIsTicketModalOpen(true); // 모달 열기
  };

  const handleCloseTicketModal = () => {
    setIsTicketModalOpen(false); // 모달 닫기
  };

  const formatDate = (dateArray: number[]) => {
    // 배열 길이가 7(마이크로초 포함된 경우), 배열 길이가 6(초까지 조회됨), 배열 길이가 5(분까지만 조회됨)
    if (dateArray.length === 7) {
      // 마이크로초 값은 무시하고 6자리까지만 사용
      const [year, month, day, hour, minute, second] = dateArray.slice(0, 6);
      return new Date(year, month - 1, day, hour, minute, second);
    } else if (dateArray.length === 6) {
      const [year, month, day, hour, minute, second] = dateArray;
      return new Date(year, month - 1, day, hour, minute, second);
    } else if (dateArray.length === 5) {
      const [year, month, day, hour, minute] = dateArray;
      return new Date(year, month - 1, day, hour, minute, 0); // 초는 0으로 설정
    } else {
      // 기본 처리 또는 오류 방지 로직
      return "유효하지 않은 날짜 형식입니다.";
    }
  };


  const renderDate = (date: any) => {
    if (Array.isArray(date)) {
      return formatDate(date).toLocaleString();
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleString();
    } else {
      return date;
    }
  };

  const translateMembershipType = (type: string): string => {
    switch (type) {
      case "MONTH":
        return "1개월";
      case "SIX_MONTH":
        return "6개월";
      case "YEAR":
        return "12개월";
      default:
        return type;
    }
  };

  const fetchLinkedAccounts = async () => {
    if (userInfo?.socialAccountId) {
      const fetchedAccounts = await Promise.all(
          userInfo.socialAccountId.map(async (accountId) => {
            try {
              return await api.getUserInfo(accountId);
            } catch (error) {
              return null;
            }
          })
      );
      setLinkedAccounts(fetchedAccounts.filter(account => account !== null) as CustomerInfoDTO[]); // 상태 업데이트
    }
  };

  // 연동 계정 정보를 생성하는 함수
  const renderLinkedAccounts = () => {
    const formatDate = (dateArray: number[]) => {
      if (dateArray.length >= 3) {
        // 배열에서 연도, 월, 일만 사용
        const [year, month, day] = dateArray.slice(0, 3);
        return new Date(year, month - 1, day).toLocaleDateString(); // 년-월-일 형식으로 출력
      } else {
        // 기본 처리 또는 오류 방지 로직
        return "유효하지 않은 날짜 형식입니다.";
      }
    };

    const renderDate = (date: any) => {
      if (Array.isArray(date)) {
        return formatDate(date);
      } else if (typeof date === 'string') {
        return new Date(date).toLocaleDateString();
      } else {
        return date;
      }
    };

    return linkedAccounts.length > 0 ? (
        <TableRow>
          <TableCell align="center"><strong>연동계정</strong></TableCell>
          <TableCell align="center">
            {linkedAccounts.map((account) => (
                <div
                    key={account.userId}
                    style={{
                      border: '1px solid #0000FF', // 파란색 테두리
                      padding: '10px',
                      margin: '5px 0',
                      borderRadius: '4px', // 둥근 모서리
                      display: 'inline-block',
                      width: '100%',
                      color: '#0000FF', // 진한 파란색 텍스트
                    }}
                >
            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
              {`${account.provider}`}
            </span>
                  {`(유저ID: ${account.userId}) || 연동일: ${renderDate(account.signUpDate)}`}
                </div>
            ))}
          </TableCell>
        </TableRow>
    ) : null;
  };


  return (
      <Box component="form" display="flex" p={2}>
        <Grid container spacing={2}>
          {/* Left : 회원 정보 */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{padding: '16px'}}>
              <Typography variant="h6" align="center">회원 정보</Typography>
              {userInfo && (
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center"><strong>ID</strong></TableCell>
                          <TableCell align="center">
                            {userInfo.userId}
                            <Button 
                              size="small" 
                              variant="outlined" 
                              href={userInfo.redirectLoginUrl || ""} 
                              target='_blank'
                              style={{marginLeft: '10px'}}
                            >
                              회원 로그인
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>인증서비스 제공자</strong></TableCell>
                          <TableCell align="center">
                            <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                              {userInfo.provider}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>이름</strong></TableCell>
                          <TableCell align="center">{userInfo.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>휴대폰</strong></TableCell>
                          <TableCell align="center">{userInfo.phoneNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>E-mail</strong></TableCell>
                          <TableCell align="center">{userInfo.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>가입일</strong></TableCell>
                          <TableCell align="center">{renderDate(userInfo.signUpDate)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>직업</strong></TableCell>
                          <TableCell align="center">{userInfo.position}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>가입경로</strong></TableCell>
                          <TableCell align="center">{userInfo.funnels}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>가입목적</strong></TableCell>
                          <TableCell align="center">
                            {userInfo.purposeOfFunnels?.join(', ')} {/* 쉼표로 구분해서 한 줄로 표시 */}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>마케팅 동의</strong></TableCell>
                          <TableCell
                              align="center">{userInfo.marketingAgreement ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>SMS 동의</strong></TableCell>
                          <TableCell align="center">{userInfo.isSmsOn ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>E-mail 동의</strong></TableCell>
                          <TableCell align="center">{userInfo.isEmailOn ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>푸쉬 동의</strong></TableCell>
                          <TableCell align="center">{userInfo.isPushOn ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>멤버쉽 구독</strong></TableCell>
                          <TableCell
                              align="center">{userInfo.isSubscribedToMembership ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center"><strong>최근 방문일</strong></TableCell>
                          <TableCell
                              align="center">{userInfo.recentVisitDate ? renderDate(userInfo.recentVisitDate) : 'N/A'}</TableCell>
                        </TableRow>
                        {renderLinkedAccounts()}
                      </TableBody>
                    </Table>
                  </TableContainer>
              )}
            </Paper>
          </Grid>

          {/* Right : 구매 정보 (상단) 및 멤버쉽 정보 (하단) */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {/* 구매 정보 */}
              <Grid item xs={12}>
                <Paper elevation={3} style={{padding: '16px'}}>
                  <Typography variant="h6" align="center">구매 정보</Typography>
                  {purchaseInfo && (
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center"><strong>총 구매액</strong></TableCell>
                              <TableCell
                                  align="center">{purchaseInfo.totalPurchaseAmount.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>멤버쉽 구매 총액</strong></TableCell>
                              <TableCell
                                  align="center">{purchaseInfo.membershipPurchaseAmount.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>구매 상품명</strong></TableCell>
                              <TableCell
                                  align="center">{purchaseInfo.membershipPurchaseItem || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>멤버쉽 구매 횟수</strong></TableCell>
                              <TableCell
                                  align="center">{purchaseInfo.membershipSubscriptionCount}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>플러스 구매 횟수</strong></TableCell>
                              <TableCell align="center">{purchaseInfo.plusPurchaseCount}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>플러스 구매 총액</strong></TableCell>
                              <TableCell
                                  align="center">{purchaseInfo.plusPurchaseAmount.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>잔여 티켓 수</strong></TableCell>
                              <TableCell align="center">
                                {/* 간격을 추가하기 위한 Box 사용 */}
                                <Box display="flex" alignItems="center" justifyContent="center">
                                  {purchaseInfo.availableTicketsCount}
                                  {/* 간격 추가 */}
                                  <Box ml={2} />
                                  <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() => handleUserTicketsPage(Number(userId))}
                                  >
                                    이용권 조회
                                  </Button>
                                </Box>
                              </TableCell>
                            </TableRow>
                            {/*<TableRow>*/}
                            {/*  <TableCell colSpan={2} align="center">*/}
                            {/*    <Button*/}
                            {/*        variant="outlined"*/}
                            {/*        color="primary"*/}
                            {/*        onClick={handleTicketCreation}*/}
                            {/*        style={{ marginTop: '8px' }}*/}
                            {/*    >*/}
                            {/*      티켓생성 (무료이용권 발급)*/}
                            {/*    </Button>*/}
                            {/*  </TableCell>*/}
                            {/*</TableRow>*/}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  )}
                </Paper>
              </Grid>

              {/* 멤버쉽 정보 */}
              <Grid item xs={12}>
                <Paper elevation={3} style={{padding: '16px'}}>
                  <Typography variant="h6" align="center">멤버쉽 정보
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOpenMembershipModal}
                        style={{marginLeft: '16px'}}
                    >
                      멤버쉽관리
                    </Button>
                  </Typography>
                  {membershipInfo && (
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center"><strong>멤버쉽 타입</strong></TableCell>
                              <TableCell
                                  align="center">{translateMembershipType(membershipInfo.membershipType)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>멤버쉽 시작일</strong></TableCell>
                              <TableCell
                                  align="center">{renderDate(membershipInfo.membershipStartedAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>멤버쉽 종료일</strong></TableCell>
                              <TableCell
                                  align="center">{renderDate(membershipInfo.membershipExpiredAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>멤버쉽 결제 방법</strong></TableCell>
                              <TableCell
                                  align="center">{membershipInfo.membershipMethod}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>할인 사용 횟수</strong></TableCell>
                              <TableCell
                                  align="center">{membershipInfo.discountUsageCount}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>렌더링 잔여 횟수</strong></TableCell>
                              <TableCell align="center">{membershipInfo.renderCount}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>무료체험 상태</strong></TableCell>
                              <TableCell
                                  align="center">{membershipInfo.membershipTrialState}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center"><strong>무료체험 만료일</strong></TableCell>
                              <TableCell
                                  align="center">{renderDate(membershipInfo.membershipTrialDate)}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* 멤버쉽 업데이트 모달 */}
        <Dialog open={isMembershipModalOpen} onClose={handleCloseMembershipModal} maxWidth="sm"
                fullWidth>
          <DialogContent>
            <MembershipUpdatePage/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMembershipModal} color="primary">닫기</Button>
          </DialogActions>
        </Dialog>

        {errorMessage && (
            <Dialog open={true} onClose={() => setErrorMessage('')}>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <Typography>{errorMessage}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorMessage('')} color="primary"
                        style={{marginTop: '1'}}>
                  닫기
                </Button>
              </DialogActions>
            </Dialog>
        )}

        {/* 이용권 조회 모달 */}
        <Dialog open={isTicketModalOpen} onClose={handleCloseTicketModal} maxWidth="lg" fullWidth>
          <DialogTitle>이용권 조회</DialogTitle>
          <DialogContent>
            {selectedUserId && <TicketsPage userId={selectedUserId.toString()} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTicketModal} color="primary">닫기</Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
};

export default UserDetailsPage;
