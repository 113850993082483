import React, { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Container,
  Box,
  SelectChangeEvent,
} from '@mui/material'
import { ModalDTO, ModalType } from '../../model/ModalModel'
import ModalApi from '../../modules/modal'
import styles from '../../style/modal/ModalDetailPage.module.css'

const ModalCreatePageView: React.FC = () => {
  const [modal, setModal] = useState<ModalDTO>({
    id: 0,
    title: '',
    content: '',
    modalType: ModalType.ERROR,
    display: true,
    order: 0,
    createdAt: '',
    updatedAt: '',
  })
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const handleResizeObserverError = (event: Event) => {
      event.preventDefault()
    }
    window.addEventListener('error', handleResizeObserverError)
    return () => {
      window.removeEventListener('error', handleResizeObserverError)
    }
  }, [])

  const handleChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | SelectChangeEvent<ModalType>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement
    setModal((prev) => ({
      ...prev,
      [name]: name === 'display' ? value === 'true' : value,
    }))
  }

  const handleSave = async () => {
    try {
      await new ModalApi().createModal(modal)
      window.location.href = '/modal/list' // 모달 리스트 페이지로 리다이렉트
    } catch (err) {
      setError('모달을 생성하는 중 오류가 발생했습니다.')
    }
  }

  const handleCancel = () => {
    window.location.href = '/modal/list' // 모달 리스트 페이지로 리다이렉트
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        모달 생성
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Box className={styles.field}>
        <Typography className={styles.label}>제목:</Typography>
        <TextField
          name="title"
          value={modal.title}
          onChange={handleChange}
          fullWidth
        />
      </Box>
      <Box className={styles.field}>
        <Typography className={styles.label}>내용:</Typography>
        <TextField
          name="content"
          value={modal.content}
          onChange={handleChange}
          fullWidth
          multiline
        />
      </Box>
      <Box className={styles.field}>
        <Typography className={styles.label}>타입:</Typography>
        <FormControl fullWidth>
          <Select
            name="modalType"
            value={modal.modalType}
            onChange={handleChange}
            fullWidth
          >
            {Object.values(ModalType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className={styles.field}>
        <Typography className={styles.label}>표시 여부:</Typography>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            name="display"
            value={modal.display.toString()}
            onChange={handleChange}
          >
            <FormControlLabel value="true" control={<Radio />} label="예" />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="아니오"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {/* <Box className={styles.field}>
        <Typography className={styles.label}>순서:</Typography>
        <TextField
          name="order"
          type="number"
          value={modal.order}
          onChange={handleChange}
          fullWidth
        />
      </Box> */}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button onClick={handleSave} color="primary" variant="contained">
          저장
        </Button>
        <Button
          onClick={handleCancel}
          color="secondary"
          variant="outlined"
          style={{ marginLeft: '8px' }}
        >
          취소
        </Button>
      </Box>
    </Container>
  )
}

export default ModalCreatePageView
