import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { ModalDTO, ModalType } from '../../model/ModalModel'
import ModalApi from '../../modules/modal'
import styles from '../../style/modal/ModalDetailPage.module.css'
import ModalUpdateView from './ModalUpdateView'
import ModalDetailView from './ModalDetailView'
import ModalDeleteConfirmDialog from './ModalDeleteConfirmDialog'

interface ModalDetailProps {
  modalId: number
  open: boolean
  onClose: () => void
}

const ModalDetail: React.FC<ModalDetailProps> = ({ modalId, open }) => {
  const [modal, setModal] = useState<ModalDTO | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [editedModal, setEditedModal] = useState<ModalDTO | null>(null)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false)

  useEffect(() => {
    const fetchModal = async () => {
      try {
        const modalData = await new ModalApi().getModal(modalId)
        setModal(modalData)
        setEditedModal(modalData)
      } catch (err) {
        setError('모달 데이터를 가져오는 중 오류가 발생했습니다.')
      } finally {
        setLoading(false)
      }
    }

    if (open) {
      fetchModal()
    }
  }, [modalId, open])

  const handleUpdate = () => {
    setIsEditMode(true)
  }

  const handleSave = async () => {
    if (editedModal) {
      const updatedModal = await new ModalApi().updateModal(
        modalId,
        editedModal
      )
      setModal(updatedModal)
      setIsEditMode(false)
    }
  }

  const handleDelete = () => {
    setIsDeleteConfirmOpen(true)
  }

  const confirmDelete = async () => {
    try {
      await new ModalApi().deleteModal(modalId)
      setIsDeleteConfirmOpen(false)
      window.location.reload() // 페이지 새로고침
    } catch (err) {
      setError('모달 데이터를 삭제하는 중 오류가 발생했습니다.')
    }
  }

  const handleChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | SelectChangeEvent<ModalType>
  ) => {
    const { name, value } = e.target
    setEditedModal((prev) => {
      if (!prev) return null
      if (name === 'display') {
        return { ...prev, [name]: value === 'true' }
      }
      return { ...prev, [name]: value }
    })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => window.location.reload()}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>모달 상세보기</DialogTitle>
        <DialogContent>
          {loading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
          {!loading && !error && modal && (
            <>
              {isEditMode ? (
                <ModalUpdateView modal={editedModal!} onChange={handleChange} />
              ) : (
                <ModalDetailView modal={modal} />
              )}
            </>
          )}
          {!loading && !error && !modal && (
            <Typography>모달 데이터를 찾을 수 없습니다.</Typography>
          )}
        </DialogContent>
        <DialogActions className={styles.actions}>
          <div>
            {isEditMode ? (
              <Button onClick={handleSave} color="primary">
                저장
              </Button>
            ) : (
              <Button onClick={handleUpdate} color="primary">
                수정
              </Button>
            )}
            <Button onClick={handleDelete} color="secondary">
              삭제
            </Button>
          </div>
          <Button onClick={() => window.location.reload()}>닫기</Button>
        </DialogActions>
      </Dialog>

      <ModalDeleteConfirmDialog
        open={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onConfirm={confirmDelete}
      />
    </>
  )
}

export default ModalDetail
