import {
  Container,
  TextField,
  FormGroup,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UsersApi from "../../modules/users";
import { UserModel } from "../../model/UserModel";

interface UserRoleUpdatePageProps {
  userId: string; // 모달을 통해 전달받은 userId (string 타입)
  onClose: () => void; // 모달 닫기 핸들러
  onSubmit: () => void; // 권한 수정 후 호출할 핸들러
}

const UserRoleUpdatePage: React.FC<UserRoleUpdatePageProps> = ({ userId, onClose , onSubmit}) => {
  const [formData, setFormData] = useState<UserModel>({
    userId: 0,
    name: "",
    email: "",
    role: [],
    isCertified: false,
    provider: []
  });

  const roles = ["ROLE_USER", "ROLE_MEMBERSHIP", "ROLE_ADMIN", "ROLE_SUPERUSER", "ROLE_COMPANY", "ROLE_ENTERPRISE"];
  const api = new UsersApi();

  // 유저 정보 가져오기
  const fetchUsersInfo = async (id: string) => {
    try {
      const userInfo = await api.getUsersInfo(id);
      if (userInfo) {
        setFormData(userInfo);
      }
    } catch (error) {
      console.error("유저 정보 가져오기 실패", error);
      alert("유저 정보를 가져오는데 실패했습니다. 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    fetchUsersInfo(userId);
  }, [userId]);

  // 입력 필드 변화 핸들러
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (name === "role") {
      setFormData((prevData) => ({
        ...prevData,
        role: checked
            ? [...prevData.role, value]
            : prevData.role.filter((role) => role !== value),
      }));
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  // 권한 수정 제출 핸들러
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      // 권한 업데이트 로직
      const updateFormData = {
        role: formData.role,
        isDeleted: false,
      };
      const res = await api.updateUserRole(userId, updateFormData);
      if ('status' in res && res.status === 200) {
        alert('수정되었습니다.');
        onSubmit(); // 권한 수정 후 호출되는 부모 함수 호출
      } else {
        alert('오류가 발생했습니다. 관리자에게 문의해주세요.');
      }
    } catch (error) {
      console.error('권한 수정 오류', error);
      alert('권한 수정 중 오류가 발생했습니다.');
    }
  };

  return (
      <Container maxWidth="sm">
        <Card sx={{ mt: 2, p: 2 }}>
          <CardContent>
            <Box>
              <Typography
                  variant="h5"
                  component="div"
                  gutterBottom
                  sx={{ mb: 2 }}
              >
                권한 수정
              </Typography>
              <FormGroup sx={{ mb: 2 }}>
                <TextField
                    label="이름"
                    variant="outlined"
                    name="name"
                    value={formData.name}
                    fullWidth
                    disabled
                />
              </FormGroup>
              <FormGroup sx={{ mb: 2 }}>
                <TextField
                    label="이메일"
                    variant="outlined"
                    name="email"
                    value={formData.email}
                    fullWidth
                    disabled
                />
              </FormGroup>
              <FormGroup sx={{ mb: 2 }}>
                <Typography component="legend">권한 체크</Typography>
                {roles.map((role) => (
                    <FormControlLabel
                        key={role}
                        control={
                          <Checkbox
                              checked={formData.role.includes(role)}
                              onChange={handleChange}
                              name="role"
                              value={role}
                          />
                        }
                        label={role}
                    />
                ))}
              </FormGroup>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={handleSubmit} // 수정 버튼 클릭 시 handleSubmit 실행
                >
                  수정
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={onClose}
                >
                  취소
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
  );
};

export default UserRoleUpdatePage;
