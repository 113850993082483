import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Alert,
} from '@mui/material';
import OrdersApi from '../../modules/order';
import UsersApi from "../../modules/users";
import {ItemDTO, OrderSummaryDTO} from '../../model/OrderModel';
import {CustomerBasicInfoDTO, CustomerInfoDTO} from '../../model/UserModel';
import {useNavigate} from "react-router-dom";

interface OrderReview {
  userId: number;
  orderedPrice: number;
  discount: number;
  price: number;
  item: {
    id: number;
    name: string;
  };
}

const CreateOrderPage: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [itemId, setItemId] = useState<number>(0);
  const [items, setItems] = useState<ItemDTO[]>([]);
  const [selectedItem, setSelectedItem] = useState<ItemDTO | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isOrderConfirmationOpen, setIsOrderConfirmationOpen] = useState<boolean>(false);

  // 주문 검토를 위한 상태
  const [orderReview, setOrderReview] = useState<OrderReview | null>(null);
  const [errorInModal, setErrorInModal] = useState<string | null>(null);

  // 회원 검색을 위한 상태
  const [isUserSearchOpen, setIsUserSearchOpen] = useState(false);
  const [userSearchType, setUserSearchType] = useState<string>('nameOrEmail');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<CustomerBasicInfoDTO[]>([]);
  const [userSearchPage, setUserSearchPage] = useState(0);
  const [userSearchRowsPerPage, setUserSearchRowsPerPage] = useState(5);
  const [userInfo, setUserInfo] = useState<CustomerInfoDTO | null>(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const navigate = useNavigate();
  // handleKeyPress 함수 정의
  const handleKeyPress = (action: () => void) => (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      action();
    }
  };

  // 주문 아이템 목록 불러오기
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const ordersApi = new OrdersApi();
        const response = await ordersApi.getItemsByType(['MEMBERSHIP', 'PREMIUMCUSTOM', 'PREMIUMBUNDLE']); // 원하는 타입들 전달
        setItems(response);
      } catch (error) {
        alert('Failed to fetch items by types');
      }
    };
    fetchItems();
  }, []);

  const handleItemChange = (event: SelectChangeEvent<number>) => {
    const itemId = Number(event.target.value);
    const selectedItem = items.find(item => item.id === itemId) || null;
    setSelectedItem(selectedItem); // 선택한 아이템을 상태로 저장
  };

  const handleCreateOrderReview = async () => {
    if (!userId || !selectedItem) {
      setErrorMessage('유저 ID와 아이템을 선택해주세요.');
      return;
    }

    try {
      const usersApi = new UsersApi();
      const userInfo = await usersApi.getUserInfo(Number(userId)); // 유저 정보 조회

      const discount = selectedItem.discount;
      const orderedPrice = selectedItem.price - discount;

      // 주문 요약 정보 생성 (검토용)
      const newOrderReview: OrderReview = {
        userId: Number(userId),
        orderedPrice,
        discount,
        price: selectedItem.price,
        item: {
          id: selectedItem.id,
          name: selectedItem.name,
        },
      };

      // 검토용 데이터를 상태로 저장
      setOrderReview(newOrderReview);
      setUserInfo(userInfo);  // userInfo를 상태에 저장
      setIsOrderConfirmationOpen(true); // 주문 확인 모달 열기
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(`유저 Id ${userId} 에 해당하는 유저가 존재하지 않습니다.`);
      return;
    }
  };


  const handleConfirmOrder = async () => {
    try {
      if (orderReview) {
        const ordersApi = new OrdersApi();
        const orderData = {
          itemId: orderReview.item.id,
          mergeType: 'SINGLE', // 고정된 값
          isDiscount: orderReview.discount > 0, // discount가 0보다 크면 true, 아니면 false
        };

        // 주문 생성 API 호출 및 응답으로 새로운 주문 정보 수신
        const createdOrder: OrderSummaryDTO = await ordersApi.createOrder(orderReview.userId, orderData);

        console.log("createdOrder: ", createdOrder);

        alert('주문이 성공적으로 생성되었습니다.');

        // 주문 생성 후 상세 페이지로 이동
        navigate(`/orders/detail/${createdOrder.orderId}/${userId}/${createdOrder.status}`);

        setIsOrderConfirmationOpen(false);  // 모달 닫기
        setErrorInModal(null); // 에러 상태 초기화
      }
    } catch (error) {
      // 모달 내에 에러 메시지 표시
      setErrorInModal('주문 생성 중 오류가 발생했습니다. ID를 확인하세요');
    }
  };

  // 회원 찾기 관련 함수들
  const handleSearchUsers = async () => {
    if (!searchQuery.trim()) {
      alert('검색값을 입력해 주세요.');
      return;
    }

    try {
      const usersApi = new UsersApi();
      const response = await usersApi.searchAdminUsers(
          userSearchPage, // 현재 페이지를 사용하여 검색
          userSearchRowsPerPage,
          userSearchType,
          searchQuery
      );

      setSearchResults(response.items);
      setTotalUsers(response.totalCount);
    } catch (error) {
      alert('회원 검색 중 오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    // 페이지 번호 변경 또는 페이지당 행 수 변경 시 회원 검색 호출
    if (isUserSearchOpen) {
      handleSearchUsers();
    }
  }, [userSearchPage, userSearchRowsPerPage]);

  const handleUserSearchChangePage = (event: unknown, newPage: number) => {
    setUserSearchPage(newPage);
  };

  const handleUserSearchChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = parseInt(event.target.value, 10);
    setUserSearchRowsPerPage(newSize);
    setUserSearchPage(0); // 페이지 번호를 첫 페이지로 초기화
  };

  const handleUserSelect = (selectedUserId: number) => {
    setUserId(selectedUserId.toString());
    setIsUserSearchOpen(false);
  };

  const handleOpenUserSearch = () => {
    setSearchQuery('');
    setSearchResults([]);
    setIsUserSearchOpen(true);
  };

  const handleCloseUserSearch = () => {
    setIsUserSearchOpen(false);
  };

  const handleSearchButtonClick = () => {
    setUserSearchPage(0); // 새로운 검색 시 페이지 초기화
    handleSearchUsers(); // 검색 호출
  };

  return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Paper elevation={3} style={{padding: '16px', width: '100%', maxWidth: '600px'}}>
          <Box display="flex" justifyContent="center" mb={2}>
            <Typography variant="h5" component="h2">
              주문 생성
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpenUserSearch}
                    style={{marginLeft: '2rem'}}>
              회원ID찾기
            </Button>
          </Box>

          {errorMessage && (
              <Alert severity="error" style={{marginBottom: '16px'}}>
                {errorMessage}
              </Alert>
          )}

          <Grid container spacing={2} style={{paddingTop: '1rem'}}>
            <Grid item xs={12}>
              <TextField
                  label="회원 ID"
                  variant="outlined"
                  type="text"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  onKeyPress={handleKeyPress(handleCreateOrderReview)} // Enter 키로 주문 검토
                  fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>상품 선택</InputLabel>
                <Select
                    value={selectedItem?.id || ''}
                    onChange={handleItemChange}
                    label="상품 선택"
                    onKeyPress={handleKeyPress(handleCreateOrderReview)} // Enter 키로 주문 검토
                >
                  {items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleCreateOrderReview} fullWidth>
              주문 검토
            </Button>
          </Box>

          {/* 주문 확인 모달 */}
          <Dialog open={isOrderConfirmationOpen} onClose={() => setIsOrderConfirmationOpen(false)}>
            <DialogTitle>주문 정보 확인</DialogTitle>
            <DialogContent>
              {orderReview && (
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>회원 ID</TableCell>
                          <TableCell>{orderReview.userId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>이름</TableCell>
                          <TableCell>{userInfo?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>전화번호</TableCell>
                          <TableCell>{userInfo?.phoneNumber || 'N/A'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>주문 금액</TableCell>
                          <TableCell>{orderReview.orderedPrice.toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>할인</TableCell>
                          <TableCell>{orderReview.discount.toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>상품 가격</TableCell>
                          <TableCell>{orderReview.price.toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>상품명</TableCell>
                          <TableCell>{orderReview.item.name}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
              )}

              {errorInModal && (
                  <Typography variant="body2" color="error" style={{marginTop: '16px'}}>
                    {errorInModal}
                  </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmOrder} color="primary">
                주문 확정
              </Button>
              <Button onClick={() => setIsOrderConfirmationOpen(false)} color="secondary">
                취소
              </Button>
            </DialogActions>
          </Dialog>

          {/* 회원찾기 모달 팝업 */}
          <Dialog open={isUserSearchOpen} onClose={handleCloseUserSearch} maxWidth="sm" fullWidth>
            <DialogTitle>회원 찾기</DialogTitle>
            <DialogContent style={{maxHeight: '400px'}} dividers>
              <Box display="flex" mb={2}>
                <FormControl variant="outlined" size="small"
                             style={{marginRight: '1rem', minWidth: '150px'}}>
                  <InputLabel id="user-search-type-label">검색조건</InputLabel>
                  <Select
                      labelId="user-search-type-label"
                      value={userSearchType}
                      onChange={(e) => setUserSearchType(e.target.value)}
                      label="검색조건"
                  >
                    <MenuItem value="name">이름</MenuItem>
                    <MenuItem value="email">이메일</MenuItem>
                    <MenuItem value="phoneNumber">전화번호</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                    label={
                      userSearchType === 'phoneNumber' ? '전화번호로 검색' :
                          userSearchType === 'name' ? '이름으로 검색' :
                              '이메일로 검색'
                    }
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={handleKeyPress(handleSearchUsers)}
                    size="small"
                    style={{maxWidth: '300px'}}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearchButtonClick} // handleSearchUsers 대신 handleSearchButtonClick 사용
                    style={{marginLeft: '1rem'}}
                >
                  찾기
                </Button>

              </Box>
              <TableContainer>
                <Table>
                  <TableBody>
                    {searchResults.map((user) => (
                        <TableRow key={user.userId}>
                          <TableCell>{user.userId}</TableCell>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.phoneNumber}</TableCell>
                          <TableCell>
                            <Button variant="contained" color="primary"
                                    onClick={() => handleUserSelect(user.userId)}>
                              선택
                            </Button>
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalUsers} // 전체 유저 수를 사용하여 페이지네이션 처리
                  rowsPerPage={userSearchRowsPerPage}
                  page={userSearchPage} // 현재 페이지 상태
                  onPageChange={handleUserSearchChangePage}
                  onRowsPerPageChange={handleUserSearchChangeRowsPerPage}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseUserSearch} color="secondary">
                닫기
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
  );
};

export default CreateOrderPage;
