import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
} from '@mui/material'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ModalApi from '../../modules/modal'
import { ModalDTO } from '../../model/ModalModel'
import ModalDetail from './ModalDetailPage'
import styles from '../../style/modal/ModalDetailPage.module.css'

const ItemType = {
  CARD: 'card',
}

interface DraggableRowProps {
  modal: ModalDTO
  index: number
  moveCard: (fromIndex: number, toIndex: number) => void
  isActive: boolean
  onClick: (id: number) => void
}

const DraggableRow: React.FC<DraggableRowProps> = ({
  modal,
  index,
  moveCard,
  isActive,
  onClick,
}) => {
  const ref = React.useRef<HTMLTableRowElement>(null)
  const [, drop] = useDrop({
    accept: ItemType.CARD,
    hover(item: { index: number }) {
      if (item.index !== index) {
        moveCard(item.index, index)
        item.index = index
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CARD,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <TableRow
      ref={ref}
      className={`${styles.draggableRow} ${
        isDragging ? styles.draggableRowDragging : ''
      } ${isActive ? styles.activeRow : ''}`}
      onClick={() => onClick(modal.id)}
    >
      <TableCell>{modal.id}</TableCell>
      <TableCell>{modal.title}</TableCell>
      <TableCell className={styles.ellipsis}>{modal.content}</TableCell>
      <TableCell>{modal.modalType}</TableCell>
      <TableCell>{modal.display ? '예' : '아니오'}</TableCell>
      <TableCell>{modal.order}</TableCell>
      <TableCell>{modal.createdAt}</TableCell>
      <TableCell>{modal.updatedAt}</TableCell>
    </TableRow>
  )
}

const ModalListPage = () => {
  const [modals, setModals] = useState<ModalDTO[]>([])
  const [error, setError] = useState<string | null>(null)
  const [selectedModalId, setSelectedModalId] = useState<number | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    const fetchModals = async () => {
      try {
        const modalList = await new ModalApi().getModalList()
        setModals(modalList)
      } catch (err) {
        setError('모달 데이터를 가져오는 중 오류가 발생했습니다.')
      }
    }

    fetchModals()
  }, [])

  if (error) return <div>{error}</div>

  const handleRowClick = (id: number) => {
    setSelectedModalId(id)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedModalId(null)
  }

  const moveCard = (fromIndex: number, toIndex: number) => {
    const updatedModals = [...modals]
    const [movedModal] = updatedModals.splice(fromIndex, 1)
    updatedModals.splice(toIndex, 0, movedModal)
    setModals(updatedModals)
  }

  const handleSaveOrder = async () => {
    const modalIds = modals.map((modal) => modal.id)
    try {
      await new ModalApi().updateOrderedModal(modalIds)
      alert('모달 순서가 저장되었습니다.')
      // 상태를 업데이트하여 변경된 순서가 반영되도록 합니다.
      setModals((prevModals) =>
        prevModals.map((modal, index) => ({
          ...modal,
          order: index + 1,
        }))
      )
    } catch (err) {
      setError('모달 순서를 저장하는 중 오류가 발생했습니다.')
    }
  }

  // display가 true인 아이템 중 order가 가장 낮은 아이템 찾기
  const activeModal = modals
    .filter((modal) => modal.display)
    .sort((a, b) => a.order - b.order)[0]

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className={styles.headerBox}>
        <Typography variant="h4">모달</Typography>
        <Box display="flex" alignItems="center">
          <div className={styles.activeIndicatorContainer}>
            활성화된 모달: <div className={styles.activeIndicator}></div>
          </div>
          <Button onClick={handleSaveOrder} color="primary" variant="contained">
            순서 저장
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>제목</TableCell>
              <TableCell>내용</TableCell>
              <TableCell>타입</TableCell>
              <TableCell>표시 여부</TableCell>
              <TableCell>순서</TableCell>
              <TableCell>생성일</TableCell>
              <TableCell>수정일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modals.map((modal, index) => (
              <DraggableRow
                key={modal.id}
                modal={modal}
                index={index}
                moveCard={moveCard}
                isActive={activeModal && modal.id === activeModal.id}
                onClick={handleRowClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedModalId !== null && (
        <ModalDetail
          modalId={selectedModalId}
          open={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </DndProvider>
  )
}

export default ModalListPage
