import { Box, Button, CircularProgress, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserApi from "../../modules/users";
import { UserModel } from '../../model/UserModel';
import { useNavigate } from 'react-router-dom';

const UserListsPage = () => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState('');
  const [tempQuery, setTempQuery] = useState('');
  const navigate = useNavigate();

  const fetchUsers = async (page: number, size: number, query: string) => {
    setLoading(true);
    const api = new UserApi();
    const userList = await api.getUsersLists(page, size, query);
    console.log(userList)
    setUsers(userList.items);
    setTotalCount(userList.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(page, rowsPerPage, query);
  }, [page, rowsPerPage, query]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0);
    fetchUsers(0, rowsPerPage, tempQuery);
  };

  const handleMembershipUpdatePage = (userId: number) => {
    navigate(`/user/membership/${userId}`);
  };

  const handleRoleUpdatePage = (userId: number) => {
    navigate(`/user/role/${userId}`);
  };

  return (
    <>
      <Typography variant="h5" component="h5" gutterBottom>
        회원 리스트
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Email Search ..."
          variant="outlined"
          value={tempQuery}
          onChange={(e) => setTempQuery(e.target.value)}
          style={{ marginRight: '1rem' }}
          size="small"
        />
        <Button variant="contained" color="primary" onClick={handleSearch} >
          Search
        </Button>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>이름</TableCell>
                  <TableCell>이메일</TableCell>
                  <TableCell>권한</TableCell>
                  <TableCell>본인인증</TableCell>
                  <TableCell>가입경로</TableCell>
                  <TableCell>관리</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users && users.map((user) => (
                  <TableRow key={user.userId}>
                    <TableCell>{user.userId}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role.join(', ')}</TableCell>
                    <TableCell>{user.isCertified ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{user.provider.join(', ')}</TableCell>
                    <TableCell>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleMembershipUpdatePage(user.userId)}
                      >
                        멤버십
                      </Button>
                      {" "}
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleRoleUpdatePage(user.userId)}
                      >
                        권한
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
};



export default UserListsPage