import Api from '../configs/apiConfigs'
import { ModalCreateDTO, ModalDTO, ModalResponseDTO } from '../model/ModalModel'
import { ConvertLocalDateTimeToString } from '../utils/ConvertLocalDateTimeToString'

export default class ModalApi extends Api {
  async getModalList(): Promise<ModalDTO[]> {
    try {
      const response = await super.get<ModalResponseDTO[]>(
        `${process.env.REACT_APP_API_URL}/auth/admin/modal`
      )
      return response.data
        .sort((a, b) => a.order - b.order)
        .map((modal) => ({
          ...modal,
          createdAt: ConvertLocalDateTimeToString.convertArray(modal.createdAt),
          updatedAt: ConvertLocalDateTimeToString.convertArray(modal.updatedAt),
        }))
    } catch (error) {
      throw error
    }
  }

  async getModal(modalId: number): Promise<ModalDTO> {
    try {
      const response = await super.get<ModalResponseDTO>(
        `${process.env.REACT_APP_API_URL}/auth/admin/modal/${modalId}`
      )
      const modal = response.data
      return {
        ...modal,
        createdAt: ConvertLocalDateTimeToString.convertArray(modal.createdAt),
        updatedAt: ConvertLocalDateTimeToString.convertArray(modal.updatedAt),
      }
    } catch (error) {
      throw error
    }
  }

  async createModal(modal: ModalCreateDTO): Promise<ModalDTO> {
    try {
      const response = await super.post<ModalResponseDTO>(
        `${process.env.REACT_APP_API_URL}/auth/admin/modal`,
        modal
      )
      const createModal = response.data
      return {
        ...createModal,
        createdAt: ConvertLocalDateTimeToString.convertArray(
          createModal.createdAt
        ),
        updatedAt: ConvertLocalDateTimeToString.convertArray(
          createModal.updatedAt
        ),
      }
    } catch (error) {
      throw error
    }
  }

  async updateModal(modalId: number, modal: ModalDTO): Promise<ModalDTO> {
    try {
      const response = await super.put<ModalResponseDTO>(
        `${process.env.REACT_APP_API_URL}/auth/admin/modal/${modalId}`,
        modal
      )
      const updateModal = response.data
      return {
        ...updateModal,
        createdAt: ConvertLocalDateTimeToString.convertArray(
          updateModal.createdAt
        ),
        updatedAt: ConvertLocalDateTimeToString.convertArray(
          updateModal.updatedAt
        ),
      }
    } catch (error) {
      throw error
    }
  }

  async deleteModal(modalId: number): Promise<void> {
    try {
      await super.delete(
        `${process.env.REACT_APP_API_URL}/auth/admin/modal/${modalId}`
      )
    } catch (error) {
      throw error
    }
  }

  async updateOrderedModal(modalIds: number[]): Promise<void> {
    try {
      await super.post(
        `${process.env.REACT_APP_API_URL}/auth/admin/modal/ordered`,
        { modalIds }
      )
    } catch (error) {
      throw error
    }
  }
}
