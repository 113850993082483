import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import UsersApi from '../../modules/users';
import { SubscribedUserBasicInfoDTO } from '../../model/UserModel';
import TicketsPage from "../orders/TicketsPage";
import UserRoleUpdatePage from "./UserRoleUpdatePage";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // TicketsPage 가져오기

const AllSubscribedUsersPage: React.FC = () => {
  const [users, setUsers] = useState<SubscribedUserBasicInfoDTO[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [tempQuery, setTempQuery] = useState('');
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState<string>('nameOrEmail'); // 검색 타입 추가
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [accountStats, setAccountStats] = useState<{ totalSubscribedAccounts: number; nonSpacewalkEmailSubscribedAccounts: number } | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null); // 선택된 userId 상태
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false); // 모달 상태 관리
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false); // 권한 모달 상태 관리

  const navigate = useNavigate();

  // 플러그인 설정
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // 서울 시간대 설정
  dayjs.tz.setDefault('Asia/Seoul');

  useEffect(() => {
    // 페이지가 로딩될 때 전체 구독된 유저 조회 (searchType 없이)
    fetchUsers(page, rowsPerPage, query, '');
  }, [page, rowsPerPage]);


  useEffect(() => {
    const fetchSubscribedAccountStats = async () => {
      try {
        const usersApi = new UsersApi();
        const stats = await usersApi.getSubscribedAccountCount();
        setAccountStats(stats);
      } catch (error) {
        console.error('Failed to fetch subscribed account stats', error);
      }
    };

    fetchSubscribedAccountStats();
  }, []);

  const fetchUsers = async (page: number, size: number, query: string, searchType: string | null) => {
    setIsLoading(true);
    try {
      const usersApi = new UsersApi();
      const response = await usersApi.searchSubscribedUsers(page, size, searchType, query);
      setUsers(response.items);
      setTotalUsers(response.totalCount || 0);
    } catch (error) {
      alert("유저 조회 중 문제가 발생 하였습니다");
    } finally {
      setIsLoading(false);
    }
  };

  const roleLabels: Record<string, { label: string; color: string }> = {
    'ROLE_USER': { label: '', color: '#ADD8E6' },
    'ROLE_MEMBERSHIP': { label: 'M', color: 'green' },
    'ROLE_ADMIN': { label: 'A', color: 'red' },
    'ROLE_SUPERUSER': { label: 'S', color: 'purple' },
    'ROLE_COMPANY': { label: 'C', color: 'orange' },
    'ROLE_ENTERPRISE': { label: 'E', color: 'brown' },
  };

  const renderRoleLabels = (roles: string[]) => {
    return roles.map((role) => {
      const { label, color } = roleLabels[role] || {};
      if (!label) return null; // ROLE_USER는 아무것도 렌더링하지 않음
      return (
          <Box
              key={role}
              component="span"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 18,
                height: 18,
                borderRadius: '50%',
                backgroundColor: color,
                color: 'white',
                fontWeight: 'bold',
                marginRight: '4px',
                marginBottom: '1px'
              }}
          >
            {label}
          </Box>
      );
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchTypeChange = (event: SelectChangeEvent<string>) => {
    const newSearchType = event.target.value;
    setSearchType(newSearchType);
    setQuery('');
    setTempQuery('');
  };

  const handleSearch = async () => {
    if (!tempQuery && searchType !== 'userId' && searchType) {
      alert("검색어를 입력하세요");
      return;
    }

    setQuery(tempQuery);
    await fetchUsers(0, rowsPerPage, tempQuery, searchType);
    setPage(0);
  };

  const handleUserDetailsPage = (userId: number) => {
    navigate(`/users/details/${userId}`);
  };

  const handleUserTicketsPage = (userId: number) => {
    setSelectedUserId(userId); // 선택된 userId 설정
    setIsTicketModalOpen(true); // 모달 열기
  };

  const handleCloseTicketModal = () => {
    setIsTicketModalOpen(false); // 모달 닫기
  };

  const handleRoleUpdatePage = (userId: number) => {
    setSelectedUserId(userId); // 선택된 userId 설정
    setIsRoleModalOpen(true); // 권한 모달 열기
  };

  const handleCloseRoleModal = () => {
    setIsRoleModalOpen(false); // 권한 모달 닫기
  };

  const handleRoleUpdateSubmit = async () => {
    setIsRoleModalOpen(false); // 권한 모달 닫기
    // 모달이 열리기 전의 검색 상태를 그대로 사용하여 다시 조회
    try {
      if (searchType === 'nameOrEmail') {
        await fetchUsers(page, rowsPerPage, query, null);
      } else if (searchType === 'phoneNumber') {
        await fetchUsers(page, rowsPerPage, '', phoneNumber);
      } else if (searchType === 'userId') {
        const usersApi = new UsersApi();
        const user = await usersApi.getSubscribedUserBasicInfo(Number(tempQuery));
        setUsers([user]);
        setTotalUsers(1);
      }
    } catch (error) {
      // 오류 핸들링
      alert("회원 ID를 찾을 수 없습니다.");
    }
  };

  const handleShowAllUsersListPage = () => {
    navigate('/users/all');
  };

  const formatDate = (dateArray: number[]) => {
    // 배열 길이가 7(마이크로초 포함된 경우), 배열 길이가 6(초까지 조회됨), 배열 길이가 5(분까지만 조회됨)
    if (dateArray.length === 7) {
      // 마이크로초 값은 무시하고 6자리까지만 사용
      const [year, month, day, hour, minute, second] = dateArray.slice(0, 6);
      return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
    } else if (dateArray.length === 6) {
      const [year, month, day, hour, minute, second] = dateArray;
      return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
    } else if (dateArray.length === 5) {
      const [year, month, day, hour, minute] = dateArray;
      return new Date(year, month - 1, day, hour, minute, 0).toLocaleString();
    } else {
      // 기본 처리 또는 오류 방지 로직
      return "유효하지 않은 날짜 형식입니다.";
    }
  };

  const renderDate = (date: any) => {
    if (Array.isArray(date)) {
      return formatDate(date);
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleString();
    } else {
      return date;
    }
  };

  const handleKeyPress = (action: () => void) => (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      action();
    }
  };

  const handleRefresh = () => {
    setTempQuery('');
    setQuery('');
    setPhoneNumber(null);
    setSearchType('nameOrEmail');
    setPage(0);
    fetchUsers(0, rowsPerPage, '', null); // 초기화된 상태로 목록 재조회
  };

  return (
      <>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h5" component="h5" style={{ marginRight: '1rem' }}>
            구독 회원 리스트
          </Typography>
          <Button variant="outlined" color="primary" onClick={handleRefresh}>
            초기화
          </Button>
          {accountStats && (
              <Typography variant="body1" component="div" style={{marginLeft : '1rem'}}>
                전체 구독 회원수: {accountStats.nonSpacewalkEmailSubscribedAccounts} (@spacewalk.tech 포함: {accountStats.totalSubscribedAccounts})
              </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <FormControl variant="outlined" size="small" style={{ marginRight: '1rem', width:'150px'}}>
            <InputLabel>검색조건</InputLabel>
            <Select
                value={searchType}
                onChange={handleSearchTypeChange}
                label="검색조건"
            >
              <MenuItem value="name">이름 검색</MenuItem>
              <MenuItem value="email">이메일 검색</MenuItem>
              <MenuItem value="phoneNumber">전화번호 검색</MenuItem>
              <MenuItem value="userId">회원 ID 검색</MenuItem>
            </Select>
          </FormControl>
          <TextField
              label={searchType === 'name' ? '이름 검색' :
                  searchType === 'email' ? '이메일 검색' :
                      searchType === 'phoneNumber' ? '전화번호 검색' : '회원 ID 검색'}
              variant="outlined"
              value={tempQuery}
              onChange={(e) => setTempQuery(e.target.value)}
              onKeyPress={handleKeyPress(handleSearch)}
              style={{ marginRight: '1rem', width: '150px'}}
              size="small"
          />
          <Button variant="contained" color="primary" onClick={handleSearch} style={{ marginRight: '1rem' }}>
            검색
          </Button>
          <Button variant="contained" color="secondary" onClick={handleShowAllUsersListPage}>
            전체회원보기
          </Button>
        </Box>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>회원 ID</TableCell>
                  <TableCell>이름</TableCell>
                  <TableCell>휴대폰 번호</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>가입일</TableCell>
                  <TableCell>멤버쉽 기간</TableCell>
                  <TableCell>회원권한
                    <Tooltip
                        title={
                          <Box>
                            <Typography variant="h6">회원권한 설명</Typography>
                            <Typography variant="body2">
                              표시가 없으면 일반회원입니다 <br/>
                              M : MEMBERSHIP <br/>
                              A : ADMIN(관리자) <br/>
                              C : COMPANY <br/>
                              E : ENTERPRISE <br/>
                              S : SUPERUSER <br/>
                            </Typography>
                          </Box>
                        }
                    >
                      <IconButton size="small">
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>구독 여부</TableCell>
                  <TableCell>연동계정존재</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        회원 목록을 불러오는 중입니다...
                      </TableCell>
                    </TableRow>
                ) : users.length > 0 ? (
                    users.map((user) => (
                        <TableRow key={user.userId} style={{ cursor: 'pointer' }}>
                          <TableCell>{user.userId}</TableCell>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.phoneNumber}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{renderDate(user.signUpDate)}</TableCell>
                          <TableCell>
                            {renderDate(user.membershipStartedAt)}
                            <br />
                            ~ {renderDate(user.membershipExpiredAt)}
                          </TableCell>
                          <TableCell>
                            {renderRoleLabels(user.role)}
                            {" "} <br />
                            <Button variant="outlined" color="primary" size="small" onClick={() => handleRoleUpdatePage(user.userId)}>
                              권한수정
                            </Button>
                          </TableCell>
                          <TableCell>{user.isSubscribedToMembership ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="center" style={{width: '100px'}}>
                            {user.isUserInfoGroup ? (
                                <CheckCircleIcon style={{ color: 'green' }} />
                            ) : null}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleUserTicketsPage(user.userId)}
                            >
                              이용권 조회
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUserDetailsPage(user.userId)}
                            >
                              상세조회
                            </Button>
                          </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        검색된 회원이 없습니다.
                      </TableCell>
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* 이용권 조회 모달 */}
        <Dialog open={isTicketModalOpen} onClose={handleCloseTicketModal} maxWidth="lg" fullWidth>
          <DialogTitle>이용권 조회</DialogTitle>
          <DialogContent>
            {selectedUserId && <TicketsPage userId={selectedUserId.toString()} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTicketModal} color="primary">닫기</Button>
          </DialogActions>
        </Dialog>

        {/* 권한 수정 모달 */}
        <Dialog open={isRoleModalOpen} onClose={handleCloseRoleModal} maxWidth="sm" fullWidth>
          <DialogTitle>권한 수정</DialogTitle>
          <DialogContent>
            {selectedUserId &&
                <UserRoleUpdatePage
                    userId={selectedUserId.toString()}
                    onClose={handleCloseRoleModal}
                    onSubmit={handleRoleUpdateSubmit}/>}
          </DialogContent>
        </Dialog>
      </>
  );
};

export default AllSubscribedUsersPage;
