import {
  Container,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import { useState } from "react";
import PushNotificationApi from "../../modules/push_notification";
import { useNavigate } from "react-router-dom";

const PushNotificationFormPage = () => {
  const navigate = useNavigate();
  const api = new PushNotificationApi();
  const [formData, setFormData] = useState({
    title: "",
    link: "",
    contents: "",
    memo: "",
    type: "",
    reservationAt: null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value || "",
    }));
  };

  const [selectedDate, setSelectedDate] = useState<any>();
  const handleDateChange = (date: Dayjs | null) => {
    if (date != null) {
      const formatDate = date.format('YYYY-MM-DD HH:mm')
      setSelectedDate(formatDate);
      console.log(formatDate);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedDate !== undefined) {
      formData.reservationAt = selectedDate
    }
    console.log(formData);
    
    try {
      const res = await api.createPushNotification(formData)
      if ('status' in res) {
        const responseData = res.data;
        console.log("responseData", responseData)
        if (res.status === 200) {
          alert('등록되었습니다.');
          navigate('/push_notification/list');
        }
      } else {
        alert("오류가 발생했습니다. 관리자에게 문의해주세요.")
      }
    } catch (error) {
       alert(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 4, p: 2 }}>
        <CardContent>
          <Box>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ mb: 2 }}
            >
              푸쉬 알림 등록
            </Typography>
            <FormGroup sx={{ mb: 2 }}>
              {/* <FormLabel component="legend">제목</FormLabel> */}
              <TextField
                label="제목"
                variant="outlined"
                name="title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                required
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>타입</InputLabel>
                <Select
                  label="타입 선택"
                  name="type"
                  value={formData.type}
                  onChange={handleSelectChange}
                  required
                >
                  <MenuItem value="marketing">마케팅</MenuItem>
                  {/* <MenuItem value="option2">Option 2</MenuItem> */}
                  {/* <MenuItem value="option3">Option 3</MenuItem> */}
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <TextField
                  id="contents"
                  name="contents"
                  label="내용"
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <TextField
                label="URL 링크"
                variant="outlined"
                name="link"
                value={formData.link}
                onChange={handleChange}
                fullWidth
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <TextField
                label="메모"
                variant="outlined"
                name="memo"
                value={formData.memo}
                onChange={handleChange}
                fullWidth
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="예약날짜 선택"
                  onChange={handleDateChange}
                  minutesStep={10}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
              <FormHelperText>예약시간은 10분 단위로만 선택 가능합니다.</FormHelperText>
            </FormGroup>
            {/* <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend">
                Checkbox and Radio Inputs
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkboxInput"
                    checked={formData.checkboxInput}
                    onChange={handleChange}
                  />
                }
                label="Checkbox Input"
                sx={{ mb: 2 }}
              />

              <FormControl component="fieldset" sx={{ mb: 2 }}>
                <RadioGroup
                  name="radioInput"
                  value={formData.radioInput}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="radio1"
                    control={<Radio />}
                    label="Radio 1"
                  />
                  <FormControlLabel
                    value="radio2"
                    control={<Radio />}
                    label="Radio 2"
                  />
                </RadioGroup>
              </FormControl>
            </FormGroup> */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="success"
                sx={{ mt: 3, ml: 1 }}
                onClick={handleSubmit}
              >
                등록
              </Button>
              {/* <Button onClick={handleOpen}>Modal</Button> */}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PushNotificationFormPage;
