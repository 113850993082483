import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import * as tokenUtils from '../modules/tokenInfo'
import { useNavigate } from 'react-router-dom'

const apiUrl = `${process.env.REACT_APP_API_URL}`

export default class Api {
  private instance: AxiosInstance

  constructor(baseURL = apiUrl, timeout = 30000) {
    this.instance = axios.create({
      baseURL: baseURL,
      timeout: timeout,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    this.instance.interceptors.request.use(
      (config) => {
        const accessToken = sessionStorage.getItem('accessToken')
        const refreshToken = sessionStorage.getItem('refreshToken')

        if (config.headers && accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`
          if (refreshToken) {
            config.headers.refreshToken = `Bearer ${refreshToken}`
          }
        } else {
          config.headers.Authorization = null
          config.headers.refreshToken = null
        }

        return config
      },
      (error) => {
        console.log({ error })
        return Promise.reject(error)
      }
    )

    this.instance.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        const {
          config,
          response: { status },
        } = error

        if (status === 401) {
          try {
            const res = await this.instance.put(
              `${process.env.REACT_APP_API_URL}/auth/refresh`
            )
            const user = res.data as { token: string }

            if (user.token) {
              const tokenInfo = tokenUtils.getTokenInfo(user.token)
              const accessToken = user.token.split(' ')[0]
              const refreshToken = user.token.split(' ')[1]

              console.log('tokenInfo: ', tokenInfo)
              console.log('accessToken: ', accessToken)
              console.log('refreshToken: ', refreshToken)
              if (tokenInfo?.roles.includes('ROLE_ADMIN')) {
                config.headers.Authorization = `Bearer ${user.token}`

                sessionStorage.setItem('accessToken', user.token)
                sessionStorage.setItem('refreshToken', refreshToken)
                const formattedExpirationTime = tokenUtils
                  .convertToKST(tokenInfo.exp)
                  .toLocaleString('en-US')
                sessionStorage.setItem('expireAt', formattedExpirationTime)

                return this.instance(config)
              }
            }
          } catch (refreshError: any) {
            if (refreshError.response && refreshError.response.status === 500) {
              sessionStorage.clear()
              window.location.href = '/login'
            } else {
              console.log(refreshError)
            }
          }
        }

        return Promise.reject(error)
      }
    )
  }

  protected async get<T>(url: string, config?: AxiosRequestConfig) {
    return await this.instance.get<T>(url, config)
  }

  protected async post<T>(url: string, data: any, config?: AxiosRequestConfig) {
    return await this.instance.post<T>(url, data, config)
  }

  protected async put<T>(url: string, data?: any) {
    return await this.instance.put<T>(url, data)
  }

  protected async delete<T>(url: string, config?: AxiosRequestConfig) {
    return await this.instance.delete<T>(url, config)
  }
}
