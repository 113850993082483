enum BuildStatus {
  PREPARED = 'PREPARED',
  REQUESTED = 'REQUESTED',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  UNKNOWN = 'UNKNOWN',
}
export default BuildStatus
